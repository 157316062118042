{ useState, Fragment } = React
{ useCoffeeCallback, useCoffeeEffect, useI18n } = require 'lib/react_utils'
Button = require 'components/common/button'
Rectangle = require './rectangle'

nodeFontColor =
  light: '#FFF'
  dark: '#000'

backgroundColors = [
  {
    colorClassName: 'bg-white',
    nodeColor: '#FFF',
    fontColor: nodeFontColor.dark
  },
  {
    colorClassName: 'bg-yellow-300',
    nodeColor: '#FCD34D',
    fontColor: nodeFontColor.dark
  },
  {
    colorClassName: 'bg-yellow-500',
    nodeColor: '#F59E0B',
    fontColor: nodeFontColor.dark
  },
  {
    colorClassName: 'bg-red-500',
    nodeColor: '#EF4444',
    fontColor: nodeFontColor.light
  },
  {
    colorClassName: 'bg-green-300',
    nodeColor: '#6EE7B7',
    fontColor: nodeFontColor.dark
  },
  {
    colorClassName: 'bg-green-700',
    nodeColor: '#047857',
    fontColor: nodeFontColor.light
  },
  {
    colorClassName: 'bg-blue-300',
    nodeColor: '#93C5FD',
    fontColor: nodeFontColor.dark
  },
  {
    colorClassName: 'bg-indigo-500',
    nodeColor: '#6366F1',
    fontColor: nodeFontColor.light
  },
  {
    colorClassName: 'bg-pink-300',
    nodeColor: '#F9A8D4',
    fontColor: nodeFontColor.dark
  },
  {
    colorClassName: 'bg-gray-400',
    nodeColor: '#9CA3AF',
    fontColor: nodeFontColor.dark
  },
  {
    colorClassName: 'bg-black',
    nodeColor: '#000',
    fontColor: nodeFontColor.light
  }
]

borderColors = [
  { colorClassName: 'bg-white', borderColor: '#FFF' }
  { colorClassName: 'bg-yellow-300', borderColor: '#FCD34D' }
  { colorClassName: 'bg-yellow-500', borderColor: '#F59E0B' }
  { colorClassName: 'bg-red-500', borderColor: '#EF4444' }
  { colorClassName: 'bg-green-300', borderColor: '#6EE7B7' }
  { colorClassName: 'bg-green-700', borderColor: '#047857' }
  { colorClassName: 'bg-blue-300', borderColor: '#93C5FD' }
  { colorClassName: 'bg-indigo-500', borderColor: '#6366F1' }
  { colorClassName: 'bg-pink-300', borderColor: '#F9A8D4' }
  { colorClassName: 'bg-gray-400', borderColor: '#9CA3AF' }
  { colorClassName: 'bg-black', borderColor: '#000' }
]

shapes = [
  { label: 'Rounded', shapeClass: 'rounded' }
  { label: 'Rectangle', shapeClass: 'rectangle' }
]

SidebarNodeEditor = ({
  currentElement,
  onElementRemove,
  onNodeDataUpdate,
}) ->
  i18n = useI18n('algorithms:sidebar')

  [state, setState] = useState({
    label: currentElement.data.label ? '',
    hyperlink: currentElement.data.hyperlink ? '',
    shape: currentElement.data.shape ? '',
    background: currentElement.data.background ? '',
    fontColor: currentElement.data.fontColor ? '',
    borderColor: currentElement.data.borderColor ? '',
    lockPosition: currentElement.data.lockPosition ? false
  })

  { label, hyperlink, shape, background, fontColor, borderColor, lockPosition } = state

  handlePropsUpdate =
    useCoffeeCallback [onNodeDataUpdate, state, setState], (propObj) ->
      updatedData = _.defaults({}, propObj, state)
      onNodeDataUpdate(updatedData)
      setState(updatedData)

  useCoffeeEffect [currentElement, setState], () ->
    setState({
      label: currentElement.data.label ? '',
      hyperlink: currentElement.data.hyperlink ? '',
      shape: currentElement.data.shape ? '',
      background: currentElement.data.background ? '',
      fontColor: currentElement.data.fontColor ? '',
      borderColor: currentElement.data.borderColor ? '',
      lockPosition: currentElement.data.lockPosition ? false
    })

  <Fragment>
    <div className='algorithms-sidebar__editor-row'>
      <label>{i18n('text')}</label>
      <textarea
        placeholder='Type here'
        value={label}
        onChange={(evt) -> handlePropsUpdate({ label: evt.target.value })}
      />
    </div>

    <div className='algorithms-sidebar__editor-row'>
      <label>{i18n('shape')}</label>
      <ul className='algorithms-sidebar__radio-list'>
        {_.map shapes, ({ label, shapeClass }) ->
          <li
            key={label}
            onClick={() -> handlePropsUpdate({ shape: shapeClass })}
          >
            <input
              readOnly
              type="radio"
              name={label}
              value={label}
              checked={shape == shapeClass}
            />
            {label}
          </li>
        }
      </ul>
    </div>

    <div className='algorithms-sidebar__editor-row'>
      <label>{i18n('background')}</label>
      <div className='algorithms-sidebar__color-blocks'>
        {_.map backgroundColors, ({ colorClassName, nodeColor, fontColor }) ->
          <Rectangle
            key={colorClassName}
            colorClassName={colorClassName}
            selected={currentElement.data.background == nodeColor}
            onClick={() -> handlePropsUpdate({ background: nodeColor, color: fontColor })}
          />
        }
      </div>
    </div>

    <div className='algorithms-sidebar__editor-row'>
      <label>{i18n('border')}</label>
      <div className='algorithms-sidebar__color-blocks'>
        {_.map borderColors, ({ colorClassName, borderColor }) ->
          <Rectangle
            key={colorClassName}
            colorClassName={colorClassName}
            selected={currentElement.data.borderColor == borderColor}
            onClick={() -> handlePropsUpdate({ borderColor: borderColor })}
          />
        }
      </div>
    </div>

    <div className='algorithms-sidebar__editor-row'>
      <label>{i18n('hyperlink')}</label>
      <input
        placeholder={i18n('type_link_here')}
        value={hyperlink}
        onChange={(evt) -> handlePropsUpdate({ hyperlink: evt.target.value })}
      />
    </div>

    <div className='algorithms-sidebar__editor-row'>
      <label>{i18n('lock_position')}</label>
      <Button
        className="btn-cancel"
        label={if lockPosition then i18n('unlock_position_btn') else i18n('lock_position_btn')}
        onClick={(evt) => handlePropsUpdate({ lockPosition: !lockPosition })}
      />
    </div>

    <div className='algorithms-sidebar__editor-row'>
      <label>{i18n('delete')}</label>
      <Button
        className="btn-cancel"
        label={i18n('delete_object')}
        onClick={onElementRemove}
      />
    </div>
  </Fragment>

SidebarNodeEditor.propTypes =
  currentElement: PropTypes.object.isRequired
  onElementRemove: PropTypes.func.isRequired
  onNodeDataUpdate: PropTypes.func.isRequired

module.exports = 
module.exports = SidebarNodeEditor
