ACPVisualGuidelinesStore = require 'stores/acp_visual_guidelines_store'
ACPVisualGuidelinesActions = require 'actions/acp_visual_guidelines_actions'
ConnectStore = require 'components/enhancers/connect_store'
QuestionsStore = require 'stores/questions_store'
QuestionGroupsStore = require 'stores/question_groups_store'
OverarchingQuestionsStore = require 'stores/overarching_questions_store'
QuestionsSelector = require 'components/common/questions_selector'
{ useCoffeeCallback } = require 'lib/react_utils'

storeConnector =
  ACPVisualGuidelinesStore: (Store) ->
    editingVisualization: Store.getEditingVisualization()
  QuestionsStore: (Store) ->
    questions: Store.getQuestions()
  QuestionGroupsStore: (Store) ->
    questionGroups: Store.getQuestionGroups()
  OverarchingQuestionsStore: (Store) ->
    overarchingQuestions: Store.getOverarchingQuestions()

QuestionsSelectorField = ({
  questions
  overarchingQuestions
  questionGroups
  editingVisualization
}) ->

  updateSelectedQuestions = useCoffeeCallback [], (selectedQuestions) ->
    ACPVisualGuidelinesActions.updateEditingVisualization({
      key: 'selectedQuestions',
      value: selectedQuestions
    })

  <div>
    <QuestionsSelector
      questions={questions.merge(overarchingQuestions)}
      questionGroups={questionGroups}
      updateSelected={updateSelectedQuestions}
      selectedQuestions={editingVisualization.get('selectedQuestions', Immutable.List())}
    />
  </div>


module.exports = ConnectStore QuestionsSelectorField, [
  ACPVisualGuidelinesStore
  QuestionGroupsStore
  QuestionsStore
  OverarchingQuestionsStore
], storeConnector
