var DbDoc, acceptChange, createEditorView, mergeNewData, processExtensions, rejectChange, sectionDocShape, sectionsView, serializeEditorState,
  __slice = [].slice;

DbDoc = require('lib/db_docs/db_doc');

sectionDocShape = require('lib/db_docs/doc_shapes/document_sections_v2_section_doc_shape');

mergeNewData = require('lib/db_docs/document_instance_helpers').mergeNewData;

createEditorView = Epiditor.createEditorView, serializeEditorState = Epiditor.serializeState, acceptChange = Epiditor.acceptChange, rejectChange = Epiditor.rejectChange, processExtensions = Epiditor.processExtensions;

sectionsView = {
  map: function(doc) {
    if (doc.type === 'document_sections_section') {
      return emit(doc.mdaTopicId, doc);
    }
  }
};

module.exports = DbDoc(sectionDocShape, {
  dbSetup: {
    dbView: {
      name: 'sectionsV2',
      view: sectionsView
    }
  },
  methods: {
    setProperty: function(propKey, propValue) {
      return this.setAt(propKey)(propValue);
    },
    addAnnotationReply: function(annotationId, data) {
      var parentAnnotationId;
      parentAnnotationId = data.parentId;
      return this.updateAt('content', 'annotations')(function(annotations) {
        var newParent, parentAnnotation;
        parentAnnotation = annotations[data.parentId];
        newParent = R.assoc('replies', (parentAnnotation.replies || []).concat(annotationId), parentAnnotation);
        return R.compose(R.assoc(annotationId, data), R.assoc(parentAnnotationId, newParent))(annotations);
      });
    },
    updateAnnotation: function(annotationId, data) {
      return this.updateAt('content', 'annotations', annotationId)(R.merge(R.__, data));
    },
    updateChange: function(changeId, data) {
      return this.updateAt('content', 'changeTracker', 'changes', changeId)(R.merge(R.__, data));
    },
    removeAnnotation: function(annotation) {
      return this.updateAt('content', 'annotations')(function(annotations) {
        var newReplies, repliesLens;
        repliesLens = R.lensPath([annotation.parentId, 'replies']);
        newReplies = R.without(annotation.id, R.view(repliesLens, annotations) || []);
        return R.compose(R.omit([annotation.id].concat(__slice.call(annotation.replies))), annotation.parentId ? R.set(repliesLens, newReplies) : R.identity)(annotations);
      });
    },
    applyChangeDecision: function(changeId, accepted) {
      var referencesInsertExtension, sectionLinksExtension;
      referencesInsertExtension = require('components/document_sections/v2/editor_extensions/references_insert/references_insert');
      sectionLinksExtension = require('components/document_sections/v2/editor_extensions/section_links/section_links');
      return this.updateAt('content')(function(editorContent) {
        var changeDecisionHandler, dispatchHandler, dummyDiv, editorViewRef, updatedContent;
        changeDecisionHandler = accepted ? acceptChange : rejectChange;
        dummyDiv = document.createElement('div');
        editorViewRef = null;
        dispatchHandler = function(tr) {
          if (editorViewRef) {
            return editorViewRef.updateState(editorViewRef.state.apply(tr));
          }
        };
        editorViewRef = createEditorView({
          state: editorContent,
          onDispatch: dispatchHandler,
          mode: 'readonly',
          extensions: processExtensions([sectionLinksExtension(), referencesInsertExtension()]),
          portalsAPI: {
            remove: function() {},
            show: function() {}
          }
        }, dummyDiv);
        changeDecisionHandler(changeId)(editorViewRef.state, editorViewRef.dispatch);
        updatedContent = serializeEditorState(editorViewRef.state);
        editorViewRef.destroy();
        dummyDiv = null;
        return updatedContent;
      });
    },
    updateAdditionalData: function(key, path, value) {
      return this.updateAt(key)(function(data) {
        if (data == null) {
          data = {};
        }
        return R.assocPath(path, value, data);
      });
    },
    addPopulationGroup: function(parentId, newGroup) {
      return this.updateAt('additionalData')(function(additionalData) {
        var data, populations, populationsOrder, subgroups, subgroupsPath;
        if (additionalData == null) {
          additionalData = {};
        }
        if (parentId) {
          subgroupsPath = ['populations', parentId, 'subgroups'];
          subgroups = R.append(newGroup._id, R.path(subgroupsPath, additionalData));
          return data = R.compose(R.assocPath(subgroupsPath, subgroups), R.assocPath(['populations', newGroup._id], newGroup))(additionalData);
        } else {
          populationsOrder = (additionalData['populationsOrder'] || []).concat(newGroup._id);
          populations = additionalData['populations'] || {};
          populations[newGroup._id] = newGroup;
          return _.extend(additionalData, {
            populationsOrder: populationsOrder,
            populations: populations
          });
        }
      });
    },
    removePopulationGroup: function(groupId) {
      return this.updateAt('additionalData')(function(additionalData) {
        var orgPopulations, orgPopulationsOrder, populations, populationsOrder, _ref;
        orgPopulations = additionalData.populations, orgPopulationsOrder = additionalData.populationsOrder;
        _ref = _.includes(orgPopulationsOrder, groupId) ? {
          populationsOrder: _.without(orgPopulationsOrder, groupId),
          populations: _.omit(orgPopulations, groupId)
        } : (populations = _.chain(orgPopulations).mapObject(function(population) {
          if (_.includes(population.subgroups, groupId)) {
            return _.extend(population, {
              subgroups: _.without(population.subgroups, groupId)
            });
          } else {
            return population;
          }
        }).omit(groupId).value(), {
          populations: populations,
          populationsOrder: orgPopulationsOrder
        }), populations = _ref.populations, populationsOrder = _ref.populationsOrder;
        return _.extend(additionalData, {
          populations: populations,
          populationsOrder: populationsOrder
        });
      });
    },
    savePopulationGroup: function(population) {
      return this.setAt('additionalData', 'populations', population.get('_id'))(population.toJS());
    },
    insertRecommendationsIntoPopulation: function(populationId, recIds) {
      return this.updateAt('additionalData', 'populations', populationId)(function(population) {
        var recommendations;
        recommendations = population.recommendations || [];
        return _.extend(population, {
          recommendations: _.uniq(recommendations.concat(recIds))
        });
      });
    },
    removeRecommendationFromPopulation: function(populationId, recId) {
      return this.updateAt('additionalData')(function(additionalData) {
        var populations, recommendations, recommendationsData, _ref;
        populations = additionalData.populations || {};
        recommendations = _.without(((_ref = populations[populationId]) != null ? _ref.recommendations : void 0) || [], recId);
        recommendationsData = _.omit(additionalData.recommendationsData, recId);
        populations[populationId].recommendations = recommendations;
        return _.extend(additionalData, {
          populations: populations,
          recommendationsData: recommendationsData
        });
      });
    }
  }
});
