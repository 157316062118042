var MANUAL_REC_PREFIX, convertListToOrderedMap, keyBy, rawContentToHTML, useCoffeeMemo, visuzalizationUtils;

keyBy = require('lodash').keyBy;

rawContentToHTML = require('lib/draft_utils').rawContentToHTML;

useCoffeeMemo = require('lib/react_utils').useCoffeeMemo;

convertListToOrderedMap = require('lib/immutable_utils').convertListToOrderedMap;

MANUAL_REC_PREFIX = require('lib/etd_helper').MANUAL_REC_PREFIX;

visuzalizationUtils = {
  VISUALIZATION_TEMPLATES: [
    {
      id: 'acp_visualization_template',
      chapters: [
        {
          title: 'general_information',
          editable: false,
          deletable: false,
          chapterType: 'acp_visual_guideline_general_information_form',
          sections: [
            {
              title: "",
              sectionType: 'acp_visual_guideline_general_information_form',
              content: '',
              data: {}
            }
          ]
        }, {
          title: 'recommendations',
          editable: false,
          deletable: false,
          chapterType: 'acp_visual_guideline_recommendations',
          sections: [
            {
              title: "populations",
              sectionType: 'acp_recommendations_populations',
              content: '',
              data: {}
            }
          ]
        }, {
          title: 'clinical_considerations',
          sections: [
            {
              title: "clinical_considerations",
              sectionType: 'visual_guideline_text_section'
            }, {
              title: "citations_for_full_guideline",
              sectionType: 'visual_guideline_text_section'
            }, {
              title: "citations_for_evidence_review",
              sectionType: 'visual_guideline_text_section'
            }
          ]
        }
      ]
    }
  ],
  useVisualizationData: function(populationsSection, etds) {
    var recommendationsData, recommendationsOrder;
    recommendationsData = populationsSection.getIn(['additionalData', 'recommendationsData'], Immutable.Map());
    recommendationsOrder = populationsSection.getIn(['additionalData', 'populations'], Immutable.List()).map(function(pop) {
      return pop.get('recommendations', Immutable.List());
    }).valueSeq().flatten();
    return convertListToOrderedMap(recommendationsOrder.map(function(recId) {
      var getRationaleContent, getRecContent;
      getRecContent = function(recId) {
        var path;
        if (recId.indexOf(MANUAL_REC_PREFIX) === 0) {
          return recommendationsData.getIn([recId, 'content'], '');
        } else if (!_.isEmpty(recId.split('_')[1])) {
          if (recommendationsData.hasIn([recId, 'content'])) {
            return recommendationsData.getIn([recId, 'content'], '');
          } else {
            path = [recId.split('_')[0], 'templateData', 'conclusions', 'sections', 'multipleRecommendations', 'recommendations'];
            return etds.getIn(path).find(function(rec) {
              return rec.get('_id') === recId.split('_')[1];
            }, null, Immutable.Map()).get('content', '');
          }
        } else {
          if (recommendationsData.has(recId)) {
            return recommendationsData.getIn([recId, 'content'], '');
          } else {
            path = [recId, 'templateData', 'conclusions', 'sections', 'recommendation', 'content'];
            return etds.getIn(path);
          }
        }
      };
      getRationaleContent = function(recId) {
        if (recId.indexOf(MANUAL_REC_PREFIX) === 0) {
          return recommendationsData.getIn([recId, 'rationale'], '');
        } else if (!_.isEmpty(recId.split('_')[1])) {
          return recommendationsData.getIn([recId, 'rationale'], '');
        } else {
          return recommendationsData.getIn([recId, 'rationale'], '');
        }
      };
      return Immutable.Map({
        id: recId,
        title: '',
        content: rawContentToHTML(getRecContent(recId)),
        rationale: rawContentToHTML(getRationaleContent(recId))
      });
    }), 'id').toJS();
  }
};

module.exports = visuzalizationUtils;
