var ProjectActions, QUESTION_STATUSES_DOC_TYPE, QuestionsActions, QuestionsStatusesActions, ReferencesActions, TasksActions, alt, generateActions, mediator;

alt = require('alt');

generateActions = require('actions/utils/generate_actions');

ProjectActions = require('actions/project_actions');

QUESTION_STATUSES_DOC_TYPE = require('lib/db_docs/doc_types').QUESTION_STATUSES_DOC_TYPE;

ReferencesActions = require('actions/references_actions');

QuestionsActions = require('actions/questions_actions');

QuestionsStatusesActions = require('actions/questions_statuses_actions');

TasksActions = require('actions/tasks_actions');

mediator = require('mediator');

module.exports = generateActions({
  autoGenerate: ['fetchSuccess', 'fetchError', 'openConfirmationDialog', 'closeConfirmationDialog'],
  name: 'DashboardActions',
  actions: {
    dbChange: function(_arg) {
      var doc;
      doc = _arg.doc;
      switch (doc.docType) {
        case 'project':
          return ProjectActions.dbChange({
            doc: doc
          });
        case QUESTION_STATUSES_DOC_TYPE:
          return QuestionsStatusesActions.dbChange({
            doc: doc
          });
      }
    },
    fetch: function() {
      this.dispatch();
      ReferencesActions.fetch();
      ReferencesActions.fetchUsedReferences();
      QuestionsActions.fetch(mediator.project.id);
      QuestionsStatusesActions.fetch(mediator.project.id);
      TasksActions.fetch(mediator.project.id);
      return ProjectActions.fetch(mediator.project.id).then(this.actions.fetchSuccess)["catch"](this.actions.fetchError);
    }
  }
});
