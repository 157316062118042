ConnectStore = require 'components/enhancers/connect_store'
CollapsibleWithControls = require 'components/common/collapsible_with_controls'
DropdownMenu = require 'components/common/dropdown_menu'
EllipsizedText = require 'components/common/ellipsized_text'
IconButton = require 'components/common/icon_button'
mediator = require 'mediator'
NMAListActions = require 'actions/nma_list_actions'
NMAListStore = require 'stores/nma_list_store'
NMAQuestionModal = require 'components/nma/nma_question_modal'
NMAQuestionStore = require 'stores/nma_question_store'
Router = require 'router'
Tooltip = require 'components/common/tooltip'
{ useCoffeeCallback, useI18n } = require 'lib/react_utils'

NMAToolbarQuestion = ({ collapse, question }) ->
  onClick = useCoffeeCallback [collapse, question], ->
    collapse?()
    mediator.publish '!router:route', Router::getProjectRelativeUrl "/nma/#{question.get '_id'}"

  questionTitle = question.get 'question', ''

  <Tooltip>
    <div className="list__question" onClick={onClick} title={questionTitle}>
      <EllipsizedText height={40} lineHeight={30} text={questionTitle} />
    </div>
  </Tooltip>

NMAToolbarQuestion.propTypes =
  collapse: PropTypes.func
  question: PropTypes.instanceOf(Immutable.Map).isRequired

NMAToolbarQuestionList = ({ additionalProps: { collapse }, questions }) ->
  <div className="list__questions">
    {questions.map (question) ->
      <NMAToolbarQuestion collapse={collapse} key={question.get '_id'} question={question} />
    }
  </div>

NMAToolbarQuestionList.propTypes =
  additionalProps: PropTypes.shape(collapse: PropTypes.func)
  questions: PropTypes.instanceOf(Immutable.List).isRequired

NMAQuestionToolbar = ({ isFetching, question, questions }) ->
  i18n = useI18n 'nma:questions_list'
  questionTitle = question.get 'question', ''

  onGoToNMATable = useCoffeeCallback [question], ->
    console.log 'TODO: navigation to NMA table', question.get '_id'

  onMenuSelect = useCoffeeCallback [question, questionTitle], (option) ->
    switch option
      when 'edit'
        NMAListActions.openQuestionModal question
      when 'delete'
        confirmOptions =
          title: i18n 'confirm_delete.title'
          message: i18n 'confirm_delete.message', { questionTitle }
          confirmText: i18n '/actions.delete'
          declineText: i18n '/actions.cancel'
          yesClass: 'danger'
        mediator.dialogs.confirm confirmOptions, (confirmed) ->
          return unless confirmed
          NMAListActions.deleteQuestion(question.toJS()).then ->
            mediator.publish '!router:route', Router::getProjectRelativeUrl '/nma'

  return null if isFetching

  <div className="nma-questions-list-toolbar">
    <div className="toolbar__list nma-questions-list-toolbar__list">
      <CollapsibleWithControls passAdditionalProps withTogglableCaption>
        <Tooltip>
          <div className="list__title" title={questionTitle}>
            <EllipsizedText height={40} lineHeight={30} text={questionTitle} />
          </div>
        </Tooltip>
        <NMAToolbarQuestionList questions={questions} />
      </CollapsibleWithControls>
    </div>
    <div className="toolbar__controls">
      <button className="btn btn-apply-inverted" onClick={onGoToNMATable}>
        {i18n '/actions.go_to_nma_table'}
      </button>
      <DropdownMenu
        className="question__menu"
        menuPosition={alignment: 'end'}
        onSelect={onMenuSelect}
        opener={<IconButton className="question__menu" iconName="more" />}
        options={[
          className: 'edit'
          text: i18n '/actions.edit'
          value: 'edit'
        ,
          className: 'delete'
          text: i18n '/actions.delete'
          value: 'delete'
        ]}
      />
    </div>
    <NMAQuestionModal />
  </div>

NMAQuestionToolbar.propTypes =
  isFetching: PropTypes.bool.isRequired
  question: PropTypes.instanceOf(Immutable.Map).isRequired
  questions: PropTypes.instanceOf(Immutable.List).isRequired

storeConnector =
  NMAListStore: (Store) ->
    questions: Store.getQuestions()
  NMAQuestionStore: (Store) ->
    isFetching: Store.isFetching()
    question: Store.getCurrentQuestion()

module.exports = ConnectStore NMAQuestionToolbar, [NMAListStore, NMAQuestionStore], storeConnector
