Button = require 'components/common/button'
ChapterManagementMixin = require 'components/mixins/chapter_management_mixin'
DocumentSectionsV2Actions = require 'actions/document_sections_v2_actions'
DropdownMenu = require 'components/common/dropdown_menu'
EditAppendicesModal = require './edit_appendices_modal'
IconButton = require 'components/common/icon_button'
MdgRecommendationSectionContent = require './mdg_recommendation_section_content'
mediator = require 'mediator'
{ scrollIntoView } = require 'lib/epiditor_utils'
RecommendationSectionContent = require './recommendation_section_content'
{ getSettingsOptions, createNewComment } = require 'lib/document_sections_v2_helper'

settingsOrder = [
  'edit_appendices'
  'delete'
  'insert_recommendation'
  'add_new_text_field'
]

RecommendationSection = createReactClass
  displayName: 'RecommendationSection'

  propTypes:
    section: PropTypes.instanceOf(Immutable.Map).isRequired
    recommendation: PropTypes.instanceOf(Immutable.Map).isRequired
    question: PropTypes.instanceOf(Immutable.Map).isRequired
    onInsertRecommendation: PropTypes.func.isRequired
    onDelete: PropTypes.func.isRequired
    chapterId: PropTypes.string.isRequired
    scrollToSectionContent: PropTypes.shape({
      sectionId: PropTypes.string.isRequired,
      contentPos: PropTypes.number
    })

  mixins: [ChapterManagementMixin]

  getInitialState: ->
    editAppendicesDialogOpen: false

  handleSettingSelect: (selectedSetting) ->
    sectionId = @props.section.get('_id')
    parentSectionId = @props.section.get('parent')
    switch selectedSetting
      when 'add_new_text_field'
        DocumentSectionsV2Actions.addSection(mediator.project.id, parentSectionId, sectionId)
      when 'delete'
        sectionId = @props.section.get('_id')
        @props.onDelete(sectionId)
      when 'edit_appendices'
        @setState editAppendicesDialogOpen: true
      when 'insert_recommendation'
        @props.onInsertRecommendation({ sectionId })

  contentRef: (el) ->
    @contentEl = el

  closeEditAppendicesDialog: ->
    @setState editAppendicesDialogOpen: false

  saveAppendices: (appendices) ->
    sectionId = @props.section.get('_id')
    @setState editAppendicesDialogOpen: false
    DocumentSectionsV2Actions.updateSectionAppendices {
      projectId: mediator.project.id,
      sectionId,
      selectedAppendices: appendices.toJS()
    }

  handleAddComment: ->
    author = { id: mediator.user.id, name: mediator.user.getFullName() }
    newComment = createNewComment(author)
    projectId = mediator.project.id
    sectionId = @props.section.get('_id')
    newCommentId = newComment.id
    oldAnnotations = @props.section.getIn(['content', 'annotations'], Immutable.Map())
    updatedAnnotations = oldAnnotations.set(newCommentId, newComment).toJS()

    DocumentSectionsV2Actions.saveChapterContent {
      projectId,
      chapterId: @props.chapterId,
      sectionId,
      content: { annotations: updatedAnnotations }
    }
    DocumentSectionsV2Actions.editDiscussion { id: newCommentId, isNew: true }

  handleCommentsClick: ->
    firstAnnotation = @_getAnnotations().first()
    sectionId = @props.section.get('_id')

    DocumentSectionsV2Actions.setActiveDiscussionItem {
      sectionId
      id: firstAnnotation.get 'id'
    }

  componentDidUpdate: () ->
    if @props.scrollToSectionContent?.sectionId is @props.section.get('_id')
      scrollIntoView @contentEl
      _.defer ->
        DocumentSectionsV2Actions.setScrollToSectionContent null
      return

  _getAnnotations: () ->
    @props.section.getIn(['content', 'annotations'], Immutable.Map())

  render: ->
    {
      section,
      recommendation,
      question,
      connectDragSource
    } = @props
    { editAppendicesDialogOpen } = @state

    mdgTablesOn = mediator.services.switches.isOn 'mdgTables'
    ContentComponent = if mdgTablesOn
      MdgRecommendationSectionContent
    else
      RecommendationSectionContent
    contentProps = {
      selectedAppendices: if mdgTablesOn then undefined else section.get 'selectedAppendices'
      question
      recommendation
      onAddComment: @handleAddComment
    }

    commentsCount = @_getAnnotations().keySeq().size

    <React.Fragment>
      <div className="chapter-section-header">
        {@showChapterManagement() and <div className="chapter-section-management">
          {commentsCount > 0 &&
            <div className="btn-icon-container section-comments-count disabled">
              <Button
                type="button"
                className={classNames 'btn', 'btn-icon', 'comment'}
                label={<span>{commentsCount}</span>}
                onClick={@handleCommentsClick}
              />
            </div>
          }
          <DropdownMenu
            opener={(
              <IconButton iconName="more" className="settings-toggle" />
            )}
            options={getSettingsOptions settingsOrder}
            onSelect={@handleSettingSelect}
          />
        </div>}
      </div>
      {connectDragSource(
        <div ref={@contentRef}>
          <ContentComponent {...contentProps} />
        </div>
      )}
      <EditAppendicesModal
        isOpen={editAppendicesDialogOpen}
        onApply={@saveAppendices}
        onClose={@closeEditAppendicesDialog}
        selectedAppendices={section.get('selectedAppendices') ? Immutable.Map()}
      />
    </React.Fragment>

module.exports = RecommendationSection
