{ useI18n } = require 'lib/react_utils'
RecommendationItem = require 'components/dissemination/acp_visual_guidelines/recommendation_item'
{ RECOMMENDATION_ITEM_DND_TYPE, dragSpec, dropSpec, dragCollect, dropCollect } =
  require 'components/dissemination/acp_visual_guidelines/recommendation_item_dnd_spec'
Translation = require 'components/mixins/translation'

RecommendationItems = createReactClass

  displayName: 'RecommendationItems'

  mixins: [Translation('')]

  getRef: (el) ->
    @item = el

  render: ->
    {
      connectDropTarget
      insertRecommendation
      populationId
      recommendations
      section
      isOver
    } = @props

    <div>
      {connectDropTarget(
        <div ref={@getRef}>
          <div className="py-10">
            {isOver and recommendations.isEmpty() and
              <div className="py-10">{@i18n 'drop_here'}</div>
            }
          </div>
        </div>
      )}
      {recommendations.map (recommendationId, index) ->
        <RecommendationItem
          index={index}
          key={recommendationId}
          section={section}
          populationId={populationId}
          recommendationId={recommendationId}
        />
      }
      <div className="mt-10 text-right">
        <span className="add-link" onClick={insertRecommendation}>
          + {@i18n 'insert_recommendation'}
        </span>
      </div>
    </div>

DropTargeted = ReactDnD.DropTarget(
  RECOMMENDATION_ITEM_DND_TYPE, dropSpec, dropCollect
) RecommendationItems

module.exports = DropTargeted
