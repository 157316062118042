ConnectStore = require 'components/enhancers/connect_store'
DynamicHeightContainer = require 'components/common/dynamic_height_container'
EditableQuestionsList =
  require 'components/evidence_syntheses/questions_list/connected_editable_questions_list'
MdaTopicListItem = require 'components/mda/topics/mda_topic_list_item'
MdaTopicsStore = require 'stores/mda_topics_store'
MdaAssignMemberModal = require 'components/mda/topics/mda_assign_member_modal'
OrganizationsStore = require 'stores/organizations_store'
QuestionGroupsStore = require 'stores/question_groups_store'
QuestionsStore = require 'stores/questions_store'
QuestionsStatusesStore = require 'stores/questions_statuses_store'
Spinner = require 'components/common/spinner'
Translation = require 'components/mixins/translation'
{ MDA_TOPIC } = require 'lib/db_docs/doc_types'
{ filterTopicsByRoleAndStatus } = require 'lib/mda_helper'
{ useCoffeeCallback, useCoffeeMemo, useI18n } = require 'lib/react_utils'

questionGroups = Immutable.fromJS({ groups: [], mapping: {} })

storeConnector =
  MdaTopicsStore: (Store) ->
    assignModalOpen: Store.isAssignMemberDialogOpen()
  QuestionsStore: (Store) ->
    isFetchingQuestions: Store.isFetching()
    questions: Store.getSortedQuestionsByKey MDA_TOPIC
  QuestionsStatusesStore: (Store) -> 
    isFetchingStatuses: Store.isFetching()
    questionsStatuses: Store.getQuestionsStatuses()
  OrganizationsStore: (Store) ->
    projectFromOrganization: Store.isCurrentProjectFromOrganization()

MdaTopicsList = ({
  assignModalOpen
  isFetchingMembers
  isFetchingQuestions
  isFetchingStatuses
  members
  projectFromOrganization
  questions
  questionsStatuses
}) ->
  i18n = useI18n 'mda:topics'

  topics = useCoffeeMemo [questions, questionsStatuses], ->
    filterTopicsByRoleAndStatus questions, questionsStatuses

  return <Spinner /> if isFetchingQuestions or isFetchingStatuses

  <div className='comparison-questions-list'>
    <div>
      <DynamicHeightContainer>
        <EditableQuestionsList
          customNewGroupLabel={i18n 'new_group_area'}
          projectFromOrganization={projectFromOrganization}
          questions={topics}
          questionGroups={questionGroups}
          withNewGroupDropArea={false}
          withDnD={false}
        >
          <MdaTopicListItem />
        </EditableQuestionsList>
      </DynamicHeightContainer>
      <MdaAssignMemberModal
        isOpen={assignModalOpen}
      />
    </div>
  </div>

MdaTopicsList.propTypes =
  isFetchingQuestions: PropTypes.bool.isRequired
  isFetchingStatuses: PropTypes.bool.isRequired
  projectFromOrganization: PropTypes.bool.isRequired
  questions: PropTypes.oneOfType([
    PropTypes.instanceOf(Immutable.OrderedMap),
    PropTypes.instanceOf(Immutable.Map)]
  ).isRequired
  questionsStatuses: PropTypes.instanceOf(Immutable.List).isRequired

module.exports = ConnectStore MdaTopicsList, [
  MdaTopicsStore
  OrganizationsStore
  QuestionsStore
  QuestionsStatusesStore
], storeConnector
