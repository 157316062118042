_.namespace(module, function(require) {
  var ACPVisualGuidelinesDoc, DbHelper, MANUAL_REC_PREFIX, VisualGuidelinesRootDoc, VisualizationUtils, W, alt, appUtils, errorHandlerWithMsg, getChapterAndSectionIds, mediator, merge;
  ACPVisualGuidelinesDoc = require('lib/db_docs/acp_visual_guidelines_doc');
  errorHandlerWithMsg = require('lib/app_utils').errorHandlerWithMsg;
  alt = require('alt');
  appUtils = require('lib/app_utils');
  DbHelper = require('base/lib/db_helper');
  mediator = require('mediator');
  merge = require('lodash').merge;
  MANUAL_REC_PREFIX = require('lib/etd_helper').MANUAL_REC_PREFIX;
  VisualizationUtils = require('lib/visualization_utils');
  VisualGuidelinesRootDoc = require('lib/db_docs/visual_guidelines_root_doc');
  W = require('when');
  getChapterAndSectionIds = require('lib/mda_helper').getChapterAndSectionIds;
  return {
    gatherData: function(itemId) {
      var adapter, exportService, exportedVIId, projectId, visualizationRootId;
      adapter = mediator.services.storePersistenceAdapter;
      exportService = mediator.services.documentSectionsExportService;
      projectId = mediator.project.id;
      visualizationRootId = alt.stores.ACPVisualGuidelinesStore.getCurrentVisualizationRootId();
      exportedVIId = "" + projectId + "-" + itemId;
      return W.all([ACPVisualGuidelinesDoc.at(projectId, itemId).fetch(), VisualGuidelinesRootDoc.at(projectId, visualizationRootId).fetch()]).then(function(_arg) {
        var mainDoc, rootDoc;
        mainDoc = _arg[0], rootDoc = _arg[1];
        return getChapterAndSectionIds(projectId, rootDoc).then(function(chaptersAndSectionIds) {
          return W.all([adapter.fetch(mediator.project.id, chaptersAndSectionIds.chapterIds), adapter.fetch(mediator.project.id, chaptersAndSectionIds.sectionIds)]);
        }).then(function(_arg1) {
          var chapterDocs, chapters, chaptersToSlugMap, sectionDocs, sections;
          chapters = _arg1[0], sections = _arg1[1];
          sectionDocs = appUtils.convertRowsToMap(sections.rows || []);
          chapterDocs = appUtils.convertRowsToMap(chapters.rows || []);
          chaptersToSlugMap = exportService.convertChaptersToSlugMap(chapterDocs.toJS());
          return exportService.processChaptersAndSections(chapterDocs.toJS(), sectionDocs.toJS(), rootDoc.chaptersOrder, {
            exportDate: new Date().toISOString(),
            guidelineId: exportedVIId,
            chaptersToSlugMap: chaptersToSlugMap
          }).then(function(_arg2) {
            var fullReferencesData, processedChapters, processedSections, processedSectionsObjs, referencesIds, _ref;
            processedChapters = _arg2[0], processedSectionsObjs = _arg2[1];
            _ref = exportService.processSectionObjs(processedSectionsObjs), processedSections = _ref.processedSections, referencesIds = _ref.referencesIds, fullReferencesData = _ref.fullReferencesData;
            return {
              processedSections: merge(_.indexBy(processedSections, 'guid'), sectionDocs.toJS()),
              referencesIds: referencesIds,
              fullReferencesData: fullReferencesData,
              processedChapters: _.indexBy(processedChapters, 'guid')
            };
          });
        }).then(function(_arg1) {
          var allReferencesIds, analysis, fullReferencesData, nmaList, nmaOutcomes, populationSection, processedChapters, processedSections, recommendationIds, recommendations, references, referencesIds, viDoc;
          processedChapters = _arg1.processedChapters, processedSections = _arg1.processedSections, referencesIds = _arg1.referencesIds, fullReferencesData = _arg1.fullReferencesData;
          populationSection = _.find(processedSections, function(section) {
            return section.sectionType === 'acp_recommendations_populations';
          });
          recommendationIds = _.chain(populationSection.additionalData.populations).pluck('recommendations').flatten().compact().filter(function(recId) {
            return recId.indexOf(MANUAL_REC_PREFIX) === -1;
          }).map(function(recId) {
            return recId.split('_')[0];
          }).uniq().value();
          allReferencesIds = _.uniq(referencesIds);
          references = exportService.processReferences(allReferencesIds, fullReferencesData);
          nmaList = alt.stores.NMAListStore.getQuestions();
          nmaOutcomes = alt.stores.NMAQuestionStore.getOutcomes();
          recommendations = alt.stores.EtdsStore.getRecommendations(recommendationIds);
          recommendations = VisualizationUtils.useVisualizationData(Immutable.fromJS(populationSection), recommendations);
          analysis = nmaList.map(function(nma) {
            return Immutable.Map({
              _id: nma.get('_id'),
              title: nma.get('question'),
              interventions: nma.get('interventions'),
              outcomes: nma.get('outcomes').map(function(outcomeId) {
                return nmaOutcomes.get(outcomeId);
              })
            });
          });
          viDoc = {
            visualization: mainDoc,
            visualizationRootDoc: rootDoc,
            chapters: processedChapters,
            sections: processedSections,
            references: references.toJS(),
            recommendations: recommendations,
            analysis: analysis.toJS()
          };
          return {
            exportedVIId: exportedVIId,
            viDoc: viDoc
          };
        });
      });
    },
    exportVi: function(itemId) {
      var adapter;
      adapter = mediator.services.storePersistenceAdapter;
      return this.gatherData(itemId).then(function(_arg) {
        var exportedVIId, viDoc;
        exportedVIId = _arg.exportedVIId, viDoc = _arg.viDoc;
        return adapter.updateOrCreate(DbHelper.getVIGuidelinesDbUrl(), exportedVIId, function(doc) {
          return _.extend({}, doc, viDoc, {
            _id: exportedVIId
          });
        });
      })["catch"](function(err) {
        return errorHandlerWithMsg(err, $.t('vi:export_error'));
      });
    },
    getEmbeddableHTML: function(viUrl) {
      var attrsString;
      attrsString = 'frameborder=\"0\" width=\"100%\" height=\"100%\" allowfullscreen';
      return "<iframe src=\"" + viUrl + "\" " + attrsString + "> </iframe>";
    },
    exportVIGuidelines: function(itemId) {
      return this.exportVi(itemId).then((function(_this) {
        return function(res) {
          var newTab, viDocId, _ref;
          newTab = window.open('about:blank', '_blank');
          viDocId = (_ref = res.id) != null ? _ref : res._id;
          return newTab.location.href = DbHelper.getVIGuidelinesUrl(viDocId);
        };
      })(this));
    }
  };
});
