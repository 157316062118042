var ACPVisualGuidelinesDoc, ACP_VISUAL_GUIDELINES_PREFIX, ChapterDocShape, DocumentSectionsV2Actions, EtdsActions, MANUAL_REC_PREFIX, NMAListActions, OverarchingQuestionsActions, ReferencesActions, SectionDoc, SectionDocShape, VISUALIZATION_TEMPLATES, VISUAL_GUIDELINES_CHAPTER_PREFIX, VISUAL_GUIDELINES_ROOT_PREFIX, VISUAL_GUIDELINES_SECTION_PREFIX, VisualGuidelinesRootDoc, W, alt, appUtils, callMethod, createChapter, createChaptersStructure, docTypes, errorHandler, errorHandlerWithMsg, fetchDocs, generateActions, generateGUID, getChapterAndSectionIds, kebabCase, mediator, processTitle, visualizationChaptersView, _ref, _ref1, _ref2,
  __slice = [].slice;

_ref = require('base/lib/utils'), callMethod = _ref.callMethod, generateGUID = _ref.generateGUID;

_ref1 = require('lib/app_utils'), errorHandler = _ref1.errorHandler, errorHandlerWithMsg = _ref1.errorHandlerWithMsg;

getChapterAndSectionIds = require('lib/mda_helper').getChapterAndSectionIds;

kebabCase = require('lodash').kebabCase;

MANUAL_REC_PREFIX = require('lib/etd_helper').MANUAL_REC_PREFIX;

processTitle = require('lib/services/document_sections_exporter/utils').processTitle;

VISUALIZATION_TEMPLATES = require('lib/visualization_utils').VISUALIZATION_TEMPLATES;

ACPVisualGuidelinesDoc = require('lib/db_docs/acp_visual_guidelines_doc');

alt = require('alt');

appUtils = require('lib/app_utils');

ChapterDocShape = require('lib/db_docs/doc_shapes/document_sections_v2_chapter_doc_shape');

docTypes = require('lib/db_docs/doc_types');

DocumentSectionsV2Actions = require('actions/document_sections_v2_actions');

EtdsActions = require('actions/etds_actions');

generateActions = require('actions/utils/generate_actions');

mediator = require('mediator');

NMAListActions = require('actions/nma_list_actions');

OverarchingQuestionsActions = require('actions/overarching_questions_actions');

ReferencesActions = require('actions/references_actions');

SectionDoc = require('lib/db_docs/document_sections_v2_section_doc');

SectionDocShape = require('lib/db_docs/doc_shapes/document_sections_v2_section_doc_shape');

VisualGuidelinesRootDoc = require('lib/db_docs/visual_guidelines_root_doc');

W = require('when');

_ref2 = require('lib/doc_prefixes'), ACP_VISUAL_GUIDELINES_PREFIX = _ref2.acpVisualGuidelines, VISUAL_GUIDELINES_CHAPTER_PREFIX = _ref2.visualGuidelinesChapter, VISUAL_GUIDELINES_ROOT_PREFIX = _ref2.visualGuidelinesRoot, VISUAL_GUIDELINES_SECTION_PREFIX = _ref2.visualGuidelinesSection;

createChapter = function(additionalParams) {
  return function(chapter, rootChapterIds, parent) {
    var chapterId, doc, sectionIds, sectionsDocs, subchapterDocs, subchapterIds;
    if (parent == null) {
      parent = null;
    }
    chapterId = "" + VISUAL_GUIDELINES_CHAPTER_PREFIX + (generateGUID());
    if (rootChapterIds != null) {
      rootChapterIds.push(chapterId);
    }
    sectionsDocs = _.map(chapter.sections, function(section) {
      return SectionDocShape.defaults(_.extend({
        _id: "" + VISUAL_GUIDELINES_SECTION_PREFIX + (generateGUID()),
        sectionType: section.sectionType,
        title: section.title ? $.t("vi:templates.chapters." + section.title) : '',
        parent: chapterId
      }, additionalParams));
    });
    subchapterDocs = _.chain(chapter.subchapters).map(function(subchapter) {
      return createChapter(additionalParams)(subchapter, null, chapterId).docs;
    }).flatten().value();
    sectionIds = _.pluck(sectionsDocs, '_id');
    subchapterIds = _.chain(subchapterDocs).filter(function(subchapterDoc) {
      return subchapterDoc.parent === chapterId;
    }).pluck('_id').value();
    doc = ChapterDocShape.defaults(_.extend({
      _id: chapterId,
      parent: parent,
      slug: kebabCase(chapter.title),
      title: chapter.title ? $.t("vi:templates.chapters." + chapter.title) : '',
      subchapters: subchapterIds,
      sections: sectionIds
    }, additionalParams, _.omit(chapter, 'sections', 'title')));
    return {
      rootChapterIds: rootChapterIds,
      docs: _.flatten(__slice.call(subchapterDocs).concat([doc], __slice.call(sectionsDocs)))
    };
  };
};

createChaptersStructure = function(visualization) {
  var template;
  template = _.find(VISUALIZATION_TEMPLATES, function(tpl) {
    return tpl.id === visualization.template;
  });
  return _.reduce(template.chapters, function(acc, chapter) {
    var createChapterWithAdditionalData, docs, rootChapterIds, _ref3;
    createChapterWithAdditionalData = createChapter({
      visualizationId: visualization._id
    });
    _ref3 = createChapterWithAdditionalData(chapter, acc.rootChapterIds), rootChapterIds = _ref3.rootChapterIds, docs = _ref3.docs;
    acc.rootChapterIds = rootChapterIds;
    acc.docs = acc.docs.concat(docs);
    return acc;
  }, {
    rootChapterIds: [],
    docs: []
  });
};

visualizationChaptersView = {
  map: function(doc) {
    if (doc.type === 'document_sections_chapter' && (doc.visualizationId != null)) {
      return emit(doc.visualizationId, doc);
    }
  }
};

fetchDocs = function(projectId, docsIds, options) {
  return mediator.services.storePersistenceAdapter.fetch(projectId, docsIds, options).then(function(res) {
    return _.pluck(res.rows, 'doc');
  });
};

module.exports = generateActions({
  autoGenerate: ['changeTab', 'closeCreateOrEditDialog', 'createOrUpdateError', 'fetchError', 'fetchOneError', 'fetchOneSuccess', 'fetchRootSuccess', 'fetchSuccess', 'openCreateOrEditDialog', 'publishError', 'publishSuccess', 'saveSuccess', 'setApplying', 'updateEditingVisualization', 'dataPreparedSuccess', 'dataPreparedError', 'openExportDialog', 'closeExportDialog'],
  name: 'ACPVisualGuidelinesActions',
  actions: {
    dbChange: function(change) {
      var _ref3, _ref4;
      if (change.deleted || ((_ref3 = (_ref4 = change.doc) != null ? _ref4.type : void 0) === docTypes.DOCUMENT_SECTIONS_V2_SECTION_TYPE || _ref3 === docTypes.DOCUMENT_SECTIONS_V2_CHAPTER_TYPE)) {
        DocumentSectionsV2Actions.dbChange(change);
      }
      return this.dispatch(change);
    },
    fetch: function(_arg) {
      var adapter, dbId, docId;
      dbId = _arg.dbId, docId = _arg.docId;
      this.dispatch();
      adapter = mediator.services.storePersistenceAdapter;
      ReferencesActions.fetch();
      OverarchingQuestionsActions.fetch(dbId, true);
      NMAListActions.fetchAllQuestionsAndOutcomes({
        dbId: dbId
      });
      if (docId) {
        return ACPVisualGuidelinesDoc.at(dbId, docId).fetch().then((function(_this) {
          return function(doc) {
            var chaptersPromise, rootPromise;
            EtdsActions.fetchAll(dbId);
            rootPromise = VisualGuidelinesRootDoc.at(dbId, doc.visualizationRootId).fetch();
            chaptersPromise = adapter.fetchWithView(dbId, 'visualization_chapters_view', visualizationChaptersView, docId);
            W.all([rootPromise, chaptersPromise]).then(function(_arg1) {
              var chapters, root;
              root = _arg1[0], chapters = _arg1[1];
              DocumentSectionsV2Actions.fetchSuccess({
                alreadyUsedRecommendations: [],
                chapters: chapters,
                discussions: [],
                recommendations: [],
                root: root
              });
              return _this.actions.fetchRootSuccess(root);
            }).then(DocumentSectionsV2Actions.expandAllChapters);
            return doc;
          };
        })(this)).then(this.actions.fetchOneSuccess)["catch"]((function(_this) {
          return function(err) {
            appUtils.errorHandler(err);
            return _this.actions.fetchError(err);
          };
        })(this));
      } else {
        return ACPVisualGuidelinesDoc.at(dbId).fetchMany().then(this.actions.fetchSuccess)["catch"](this.actions.fetchError);
      }
    },
    createVisualization: function(visualization) {
      var id;
      this.actions.setApplying();
      id = generateGUID();
      return ACPVisualGuidelinesDoc.at(mediator.project.id).create(_.extend(visualization.toJS(), {
        _id: "" + ACP_VISUAL_GUIDELINES_PREFIX + id,
        visualizationRootId: "" + VISUAL_GUIDELINES_ROOT_PREFIX + id
      })).then(this.actions.createVisualizationDoc).then(this.actions.saveSuccess)["catch"]((function(_this) {
        return function(err) {
          errorHandlerWithMsg(err, $.t('vi:save_error'));
          return _this.actions.createOrUpdateError();
        };
      })(this));
    },
    updateVisualization: function(visualization) {
      this.actions.setApplying();
      return ACPVisualGuidelinesDoc.at(mediator.project.id, visualization.get('_id')).update(visualization.toJS()).then(this.actions.saveSuccess)["catch"]((function(_this) {
        return function(err) {
          errorHandlerWithMsg(err, $.t('vi:save_error'));
          return _this.actions.createOrUpdateError();
        };
      })(this));
    },
    createVisualizationDoc: function(visualization) {
      var chapterDocs, projectId;
      chapterDocs = createChaptersStructure(visualization);
      projectId = mediator.project.id;
      return VisualGuidelinesRootDoc.at(projectId, visualization.visualizationRootId).create({
        chaptersOrder: chapterDocs.rootChapterIds,
        visualizationId: visualization._id
      }).then(function(res) {
        return mediator.services.storePersistenceAdapter.bulkDocs(projectId, chapterDocs.docs);
      })["catch"]((function(_this) {
        return function(err) {
          errorHandlerWithMsg(err, $.t('vi:create_doc_root_error'));
          return _this.actions.createOrUpdateError();
        };
      })(this));
    },
    insertSubgroup: function(sectionId, populationId) {
      var newSubgroup, projectId;
      if (populationId == null) {
        populationId = null;
      }
      projectId = mediator.project.id;
      newSubgroup = {
        _id: generateGUID(),
        parentGroup: populationId,
        title: $.t('vi:population'),
        subgroups: []
      };
      return SectionDoc.at(projectId, sectionId).updateAsInstance(callMethod('addPopulationGroup', populationId, newSubgroup))["catch"](errorHandler);
    },
    deleteSubgroup: function(sectionId, populationId) {
      var projectId;
      projectId = mediator.project.id;
      return SectionDoc.at(projectId, sectionId).updateAsInstance(callMethod('removePopulationGroup', populationId)).then(function() {
        return mediator.dialogs.info($.t('vi:confirm.remove_population.delete_success'));
      })["catch"](errorHandler);
    },
    saveSubgroup: function(sectionId, population) {
      var projectId;
      projectId = mediator.project.id;
      return SectionDoc.at(projectId, sectionId).updateAsInstance(callMethod('savePopulationGroup', population))["catch"](errorHandler);
    },
    insertRecommendations: function(_arg) {
      var populationId, projectId, section, selectedQuestions, selectedQuestionsAndRecommendations, selectedRecommendations, visualizationId;
      section = _arg.section, populationId = _arg.populationId, selectedQuestionsAndRecommendations = _arg.selectedQuestionsAndRecommendations;
      visualizationId = alt.stores.ACPVisualGuidelinesStore.getCurrentVisualizationId();
      projectId = mediator.project.id;
      selectedQuestions = _.chain(selectedQuestionsAndRecommendations).map(function(qOrRId) {
        return qOrRId.split('_')[0];
      }).uniq().value();
      selectedRecommendations = _.chain(selectedQuestionsAndRecommendations).map(function(qOrRId) {
        return qOrRId.split('_')[1];
      }).compact().value();
      return ACPVisualGuidelinesDoc.at(projectId, visualizationId).updateAsInstance(callMethod('updateSelectedQuestions', selectedQuestions)).then(function(doc) {
        return fetchDocs(projectId, selectedQuestions);
      }).then(function(docs) {
        var recIds;
        recIds = _.flatten(_.map(docs, function(doc) {
          return doc.recommendationIds;
        }));
        return fetchDocs(projectId, recIds);
      }).then(function(recDocs) {
        var recIds;
        recIds = _.flatten(_.reduce(recDocs, function(acc, recDoc) {
          var ids;
          ids = recDoc.templateDef.id.indexOf('overarching') !== -1 ? _.chain(recDoc.templateData.conclusions.sections.multipleRecommendations.recommendations).filter(function(rec) {
            return _.contains(selectedRecommendations, rec._id);
          }).map(function(rec) {
            return "" + recDoc._id + "_" + rec._id;
          }).value() : [recDoc._id];
          return acc.concat(ids);
        }, []));
        return SectionDoc.at(projectId, section.get('_id')).updateAsInstance(callMethod('insertRecommendationsIntoPopulation', populationId, recIds));
      })["catch"](errorHandler);
    },
    addManualRecommendation: function(_arg) {
      var populationId, projectId, recId, sectionId;
      sectionId = _arg.sectionId, populationId = _arg.populationId;
      projectId = mediator.project.id;
      recId = "" + MANUAL_REC_PREFIX + (generateGUID());
      return SectionDoc.at(projectId, sectionId).updateAsInstance(callMethod('insertRecommendationsIntoPopulation', populationId, recId));
    },
    removeRecommendation: function(_arg) {
      var populationId, projectId, recommendationId, sectionId, visualizationRootId;
      sectionId = _arg.sectionId, populationId = _arg.populationId, recommendationId = _arg.recommendationId;
      projectId = mediator.project.id;
      visualizationRootId = alt.stores.ACPVisualGuidelinesStore.getCurrentVisualizationRootId();
      return W.all([SectionDoc.at(projectId, sectionId).updateAsInstance(callMethod('removeRecommendationFromPopulation', populationId, recommendationId)), VisualGuidelinesRootDoc.at(mediator.project.id, visualizationRootId).updateAsInstance(callMethod('removeRecommendation', recommendationId))]).then(function() {
        return mediator.dialogs.info($.t('vi:confirm.remove_recommendation.delete_success'));
      })["catch"](errorHandler);
    },
    removeVisualization: function(visualization) {
      var projectId;
      projectId = mediator.project.id;
      return ACPVisualGuidelinesDoc.at(projectId, visualization.get('_id'))["delete"]().then(function() {
        return mediator.dialogs.success($.t('vi:delete_success'));
      })["catch"](function(err) {
        return errorHandlerWithMsg(err, $.t('vi:delete_error'));
      });
    },
    updateAdditionalData: function(sectionId, field, value) {
      var projectId;
      projectId = mediator.project.id;
      return SectionDoc.at(projectId, sectionId).updateAsInstance(callMethod('updateAdditionalData', 'additionalData', [field], value))["catch"](function(err) {
        return errorHandlerWithMsg(err, $.t('vi:update_error'));
      });
    },
    updateRecommendationData: function(_arg) {
      var key, path, projectId, recommendationId, sectionId, value;
      sectionId = _arg.sectionId, recommendationId = _arg.recommendationId, key = _arg.key, value = _arg.value;
      projectId = mediator.project.id;
      path = ['recommendationsData', recommendationId, key];
      return SectionDoc.at(projectId, sectionId).updateAsInstance(callMethod('updateAdditionalData', 'additionalData', path, value))["catch"](function(err) {
        return errorHandlerWithMsg(err, $.t('vi:update_error'));
      });
    },
    updateTableData: function(tableData) {
      var visualizationRootId;
      visualizationRootId = alt.stores.ACPVisualGuidelinesStore.getCurrentVisualizationRootId();
      return VisualGuidelinesRootDoc.at(mediator.project.id, visualizationRootId).updateAsInstance(callMethod('updateTableData', tableData))["catch"](function(err) {
        return errorHandlerWithMsg(err, $.t('vi:update_error'));
      });
    },
    prepareDataForVisualizationPreview: function(itemId) {
      var VIService;
      this.dispatch();
      VIService = mediator.services.viService;
      return VIService.gatherData(itemId).then((function(_this) {
        return function(res) {
          return _this.actions.dataPreparedSuccess(res.viDoc);
        };
      })(this))["catch"]((function(_this) {
        return function(err) {
          errorHandlerWithMsg(err, $.t('vi:prepare_data_error'));
          return _this.actions.dataPreparedError();
        };
      })(this));
    }
  }
});
