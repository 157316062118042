AnimatedCollectionItem = require 'components/enhancers/animated_collection_item'
Confirmation = require 'components/common/confirmation'
QuestionsListComponentActions = require 'actions/questions_list_component_actions'
Spinner = require 'components/common/spinner'
mediator = require 'mediator'
QuestionsListMixin = require 'components/mixins/questions_list_mixin'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
ComparisonOverarchingQuestion =
  require 'components/overarching_questions/questions_list/comparison_overarching_question'
OverarchingQuestionsActions = require 'actions/overarching_questions_actions'
IconButton = require 'components/common/icon_button'
Translation = require 'components/mixins/translation'
Input = require 'components/common/input'
Modal = require 'components/common/modal'
{ Button } = ReactComponents
DragDropTypes = require 'components/evidence_syntheses/questions_list/drag_drop_types'
{ childrenTypes } = require 'lib/react_children_validation_utils'
{
  dragSpec: questionDragSpec,
  dropSpec: questionDropSpec,
  dragCollect: questionDragCollect,
  dropCollect: questionDropCollect
} = require 'components/evidence_syntheses/questions_list/question_dnd_spec'
{
  dropSpec: questionsListGroupDropSpec,
  dropCollect: questionsListGroupDropCollect
} = require 'components/evidence_syntheses/questions_list/question_group_dnd_spec'
{
  dropSpec: newQuestionsGroupDropSpec,
  dropCollect: newQuestionsGroupDropCollect
} = require 'components/evidence_syntheses/questions_list/new_question_group_drop_target_dnd_spec'
{
  DndProvider
  DragSource
  DropTarget
} = ReactDnD
{
  instanceOf
  string
  bool
  number
  oneOfType
} = PropTypes

{ TransitionGroup } = ReactTransitionGroup

#
# QUESTIONS LIST QUESTION
#

EditableQuestionsListQuestion = createReactClass
  displayName: 'EditableQuestionsListQuestion'

  propTypes:
    qId: string.isRequired

  mixins: [ CustomRenderMixin ]

  render: ->
    { connectDragSource, connectDropTarget, isDragging } = @props

    questionClassName = classNames 'questions-list-container__question',
      'is-dragging': isDragging

    connectDragSource(
      connectDropTarget(
        <div className={questionClassName} tabIndex='0'>
          {@props.children}
        </div>
      )
    )

DragSourcedQuestion = DragSource(
  DragDropTypes.QUESTION, questionDragSpec, questionDragCollect
)(EditableQuestionsListQuestion)
DropTargetedQuestion = DropTarget(
  DragDropTypes.QUESTION, questionDropSpec, questionDropCollect
)(DragSourcedQuestion)
WrappedQuestionsListQuestion = AnimatedCollectionItem DropTargetedQuestion,
  skipLeaveAnimation: true

#
# QUESTIONS LIST GROUP
#

QuestionsListGroupCaptionWithControls = createReactClass
  displayName: 'QuestionsListGroupCaptionWithControls'

  propTypes:
    caption: string.isRequired
    groupId: string.isRequired
    isEditing: bool
    isBeingSaved: bool
    isBeingDeleted: bool

  getDefaultProps: ->
    isEditing: false
    isBeingSaved: false
    isBeingDeleted: false

  mixins: [CustomRenderMixin, Translation('es:question')]

  getInitialState: ->
    captionText: @props.caption
    showDeleteConfirmation: false

  _inputRef: (el) ->
    @captionInput = el

  _containerRef: (el) ->
    @containerEl = el

  doRemoveQuestionGroup: ->
    QuestionsListComponentActions.deleteGroup @props.groupId
    @hideDeleteConfirmation()

  handleEdit: ->
    @captionInput.focus()
    QuestionsListComponentActions.toggleGroupEdit @props.groupId

  handleCaptionTextChange: (evt) ->
    @setState captionText: evt.target.value

  handleSave: ->
    { captionText } = @state
    if @props.caption is captionText
      QuestionsListComponentActions.toggleGroupEdit()
    else
      QuestionsListComponentActions.updateGroupCaption
        caption: captionText
        groupId: @props.groupId

  handleDelete: ->
    @setState showDeleteConfirmation: true

  hideDeleteConfirmation: ->
    @setState showDeleteConfirmation: false

  handleBlur: (evt) ->
    return if @containerEl.contains evt.relatedTarget
    @handleSave()

  handleAddOverarchingQuestion: ->
    OverarchingQuestionsActions.createQuestion(@props.groupId)

  cancelEdit: ->
    @setState captionText: @props.caption, => @captionInput.blur()

  componentDidUpdate: (prevProps, prevState) ->
    if @props.caption isnt prevProps.caption
      @setState captionText: @props.caption

  render: ->
    { caption, isBeingSaved, isBeingDeleted } = @props
    { captionText } = @state
    containerClassName = classNames 'questions-list-container__group-title--with-controls',
      editing: @props.isEditing

    <div className={containerClassName} ref={@_containerRef} tabIndex='0' onBlur={@handleBlur}>
      <div className='caption-text'>
        <span>{caption}</span>
      </div>
      <div className='caption-text--editing'>
        <Input
          onChange={@handleCaptionTextChange}
          onRequestSave={@handleSave}
          onRequestCancel={@cancelEdit}
          inputRef={@_inputRef}
          type='text'
          value={captionText}
         />
      </div>
      <div className='caption-controls'>
        {mediator.services.switches.isOn('overarching-questions') and <Button
          type="round"
          onClick={@handleAddOverarchingQuestion}
          >
            {@i18n '/actions.create_overarching_question'}
          </Button>
        }
        <IconButton iconName='edit' onClick={@handleEdit} />
      </div>
      <div className='caption-controls--editing'>
        <IconButton iconName='delete' onClick={@handleDelete} disabled={isBeingDeleted} />
        <IconButton iconName='save' onClick={@handleSave} disabled={isBeingSaved} />
      </div>
      {if @state.showDeleteConfirmation
        <Modal className='confirmation-modal' onClose={@hideDeleteConfirmation} isOpen>
          <Confirmation
            question={@i18n 'groups.delete_confirmation'}
            onConfirm={@doRemoveQuestionGroup}
            onCancel={@hideDeleteConfirmation}
          />
        </Modal>
      }
    </div>

EditableQuestionsListGroup = createReactClass
  displayName: 'EditableQuestionsListGroup'

  propTypes:
    caption: string
    groupId: string.isRequired
    withEditControls: bool
    children: childrenTypes(WrappedQuestionsListQuestion)
    groupEditState: instanceOf(Immutable.Map)

  mixins: [ CustomRenderMixin ]

  getDefaultProps: ->
    withEditControls: true
    isEditing: false

  render: ->
    {
      groupId,
      caption,
      connectDropTarget,
      withEditControls,
      groupEditState
    } = @props

    isEditing = groupEditState?
    containerClassName = classNames 'questions-list-container__questions-group',
      'with-caption': caption?
    questionsContainerClassName = classNames 'questions-list-container__questions-container',
      'empty': groupId isnt 'questionsWithoutGroup' and React.Children.count(@props.children) is 0

    <div className={containerClassName}>
      {if withEditControls
        <QuestionsListGroupCaptionWithControls
          caption={caption}
          groupId={groupId}
          isEditing={isEditing}
          isBeingDeleted={isEditing and groupEditState.get 'isBeingDeleted', false}
          isBeingSaved={isEditing and groupEditState.get 'isBeingSaved', false}
        />
      else
        <div className='questions-list-container__group-title'>
          {caption}
        </div>
      }
      {connectDropTarget(
        <div className={questionsContainerClassName}>
          <TransitionGroup component='div'>
            {React.Children.map @props.children, (child) ->
              React.cloneElement child, { groupId }
            }
          </TransitionGroup>
        </div>
        )
      }
    </div>

DropTargetedQuestionsListGroup = DropTarget(
  DragDropTypes.QUESTION, questionsListGroupDropSpec, questionsListGroupDropCollect
)(EditableQuestionsListGroup)

WrappedQuestionsListGroup = AnimatedCollectionItem DropTargetedQuestionsListGroup,
  skipLeaveAnimation: true

#
# NEW QUESTIONS GROUP DROP TARGET
#

NewQuestionsGroupDropArea = createReactClass
  displayName: 'NewQuestionsGroupDropArea'

  mixins: [ Translation() ]

  render: ->
    { connectDropTarget, customLabel, isTargetHovered } = @props
    containerClassName = classNames 'new-question-group-drop-area',
      hovered: isTargetHovered

    connectDropTarget(
      <div className={containerClassName} onClick={QuestionsListComponentActions.createGroup}>
        {customLabel or @i18n 'es:question.groups.new_area'}
      </div>
    )

NewQuestionsGroupDropTarget = DropTarget(
  DragDropTypes.QUESTION, newQuestionsGroupDropSpec, newQuestionsGroupDropCollect
)(NewQuestionsGroupDropArea)

#
# QUESTIONS LIST
#

EditableQuestionsList = createReactClass
  displayName: 'EditableQuestionsList'

  propTypes:
    alreadyUsedRecommendations: instanceOf(Immutable.Set)
    groupEditState: instanceOf(Immutable.Map).isRequired
    withNewGroupDropArea: bool
    withEmptyQuestionGroups: bool
    questionsByGroup: oneOfType([
      instanceOf(Immutable.OrderedMap),
      instanceOf(Immutable.Map)
    ]).isRequired
    questions: oneOfType([
      instanceOf(Immutable.OrderedMap),
      instanceOf(Immutable.Map)
    ]).isRequired
    questionsStatuses: instanceOf(Immutable.Map).isRequired,
    questionGroups: instanceOf(Immutable.Map)
    hasEverSorted: bool
    isQuestionBeingAdded: bool.isRequired
    isQuestionBeingDuplicated: bool.isRequired
    overarchingQuestions: oneOfType([
      instanceOf(Immutable.OrderedMap),
      instanceOf(Immutable.Map)
    ]).isRequired
    isFetchingQuestions: bool.isRequired
    isFetchingGroups: bool.isRequired
    isFetchingOverarchingQuestions: bool.isRequired
    customNewGroupLabel: string
    withDnD: bool

  mixins: [
    CustomRenderMixin,
    QuestionsListMixin
  ]

  getDefaultProps: ->
    alreadyUsedRecommendations: Immutable.Set()
    withNewGroupDropArea: true
    withEmptyQuestionGroups: true
    withDnD: true

  renderQuestion: (questionComponent) -> (q) =>
    qId = q.get '_id'
    {
      alreadyUsedRecommendations,
      withDnD,
      projectFromOrganization,
      questionsStatuses,
      editingQuestionId
    } = @props

    includedInDocumentSections = alreadyUsedRecommendations.includes qId

    questionProps =
        data: q,
        projectFromOrganization: projectFromOrganization
        includedInDocumentSections: includedInDocumentSections

    if @props.projectFromOrganization
      questionProps = _.extend {}, questionProps, questionStatus: questionsStatuses.get qId

    clonedQuestionElement = React.cloneElement questionComponent, questionProps

    # There is problem selecting input element text if that input is inside draggable element.
    # In some browsers (FF, IE/EDGE) it is even not possible to place cursor in such input element
    # FF - https://bugzilla.mozilla.org/show_bug.cgi?id=800050
    # IE/EDGE - https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/10375756/
    # So, we are checking here if this question is in edit mode and if so, we do not wrap it in
    # draggable wrapper making it possible to select text, place cursor as expected when question
    # is edited, making it undraggable at the same time

    if qId is editingQuestionId or not withDnD
      <div className='questions-list-container__question' key={qId}>
        {clonedQuestionElement}
      </div>
    else
      <WrappedQuestionsListQuestion
        key={qId}
        qId={qId}
      >
        {clonedQuestionElement}
      </WrappedQuestionsListQuestion>


  renderOverarchingQuestion: (question) ->
    <ComparisonOverarchingQuestion key={question.get '_id'} question={question} />

  renderQuestionsGroup: (editingGroupId, questionComponent) -> (group) =>
    { overarchingQuestions, withDnD } = @props
    groupId = group.get '_id'
    caption = group.get 'caption'
    questions = group.get 'questions'
    overarchingQuestionsForGroup =
      overarchingQuestions.getIn([groupId, 'questions']) ? Immutable.OrderedMap()

    if withDnD
      <WrappedQuestionsListGroup
        caption={caption}
        groupId={groupId}
        key={groupId}
        groupEditState={@props.groupEditState if groupId is editingGroupId}
        withEditControls={groupId isnt 'questionsWithoutGroup'}
      >
        {overarchingQuestionsForGroup.map(@renderOverarchingQuestion).toArray()}
        {questions.map(@renderQuestion(questionComponent)).toArray()}
      </WrappedQuestionsListGroup>
    else
      <React.Fragment>
        {overarchingQuestionsForGroup.map(@renderOverarchingQuestion).toArray()}
        {questions.map(@renderQuestion(questionComponent)).toArray()}
      </React.Fragment>

  isFetching: ->
    @props.isFetchingGroups or @props.isFetchingQuestions or @props.isFetchingOverarchingQuestions

  render: ->
    { customNewGroupLabel, groupEditState, questionsByGroup, withDnD } = @props
    questionComponent = React.Children.only @props.children
    groupRenderer = @renderQuestionsGroup groupEditState.get('groupId'), questionComponent

    if @isFetching()
      <div className='questions-list-container'>
        <Spinner />
      </div>
    else
      if withDnD
        <DndProvider backend={ReactDnDHTML5Backend.default}>
          <div className='questions-list-container'>
            <TransitionGroup component='div' className='items'>
              {questionsByGroup.map(groupRenderer).toArray()}
            </TransitionGroup>
            {if @props.withNewGroupDropArea or @props.hasDraggingQuestion
              <NewQuestionsGroupDropTarget customLabel={customNewGroupLabel} />
            }
          </div>
        </DndProvider>
      else
        <div className='questions-list-container'>
          <div className='questions-list-container__questions-group'>
            <div className="questions-list-container__questions-container">
              {questionsByGroup.map(groupRenderer).toArray()}
            </div>
            {if @props.withNewGroupDropArea or @props.hasDraggingQuestion
              <NewQuestionsGroupDropTarget customLabel={customNewGroupLabel} />
            }
          </div>
        </div>

module.exports = EditableQuestionsList
