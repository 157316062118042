ACPVisualGuidelinesActions = require 'actions/acp_visual_guidelines_actions'
DropdownMenu = require 'components/common/dropdown_menu'
etdHelper = require 'lib/etd_helper'
GdtEditorWithApply = Editor.EditorWithApply
IconButton = require 'components/common/icon_button'
Select = require 'components/common/select_custom'
Spinner = require 'components/common/spinner'
{ useI18n, useCoffeeCallback } = require 'lib/react_utils'
{ useRef, useState } = React
{ SelectWithCheckboxes } = ReactComponents
mediator= require 'mediator'

veryLowText = ->
  if mediator.services.switches.isServerSwitchOn 'acpFeatures'
    'insufficient'
  else
    'very_low'

RecommendationContent = ({
  populationId
  recommendation
  recommendationId
  section
  strengthOfRecommendation
}) ->
  i18n = useI18n('')
  rationaleRef = useRef()
  recommendationRef = useRef()
  sectionId = section.get('_id')

  [saving, setSaving] = useState(false)

  handleSettingSelect = useCoffeeCallback [populationId, sectionId, recommendationId], (setting) ->
    switch setting
      when 'delete'
        groupName = recommendation.get('content')
        confirmOptions =
          title: i18n 'vi:confirm.remove_recommendation.title'
          message: i18n 'vi:confirm.remove_recommendation.message'
          confirmText: i18n '/actions.delete'
          declineText: i18n '/actions.cancel'
          yesClass: 'danger'
        mediator.dialogs.confirm confirmOptions, (confirmed) ->
          return unless confirmed
          ACPVisualGuidelinesActions.removeRecommendation(
            { populationId, sectionId, recommendationId }
          )

  onChangeRecommendationData = useCoffeeCallback [sectionId, recommendationId], (key) -> (value) ->
    ACPVisualGuidelinesActions.updateRecommendationData(
      { sectionId, recommendationId, key, value }
    )

  saveContent = useCoffeeCallback [saving, sectionId, recommendationId], (key, editorRef) -> ->
    # unfortunately clicking apply also triggers onBlur so we're saving it twice,
    # which results in conflicts
    return if saving
    setSaving(true)
    ACPVisualGuidelinesActions.updateRecommendationData(
      {
        sectionId
        recommendationId
        key
        value: editorRef.current.gdtEditor.getEditorContent().toJS()
      }
    ).finally(-> setSaving(false))

  recommendationData = section.getIn(['additionalData', 'recommendationsData', recommendationId],
    Immutable.Map())

  recommendationContent = recommendationData.get('content') or recommendation.get('content')
  rationale = recommendationData.get('rationale') # we don't have it in EtD yet

  certaintyOfEvidence = recommendationData.get('certaintyOfEvidence', Immutable.List()).toJS()
     # in EtD we have certaintyOfEvidence by intervention
  strengthOfRecommendation = recommendationData.get('strengthOfRecommendation') or strengthOfRecommendation

  <div className="recommendation-content">
    <div className="flex flex-row">
      <div className="flex-grow text-right">
        <DropdownMenu
          opener={(
            <IconButton iconName="more" className="settings-toggle" />
          )}
          options={[
            { text: i18n('vi:form.settings.delete'), value: 'delete'}
          ]}
          onSelect={handleSettingSelect}
        />
      </div>
    </div>
    <div className="flex flex-row">
      <div className="flex-grow">
        <div className="mb-5">
          <h3>{i18n 'vi:recommendation'}</h3>
          <div className="recommendation-content__text-field">
            <GdtEditorWithApply
              ref={recommendationRef}
              stickControlsTo={'.chapter-section-header'}
              editorContent={recommendationContent}
              onApply={saveContent('content', recommendationRef)}
              onBlur={saveContent('content', recommendationRef)}
            />
          </div>
        </div>
        <div className="mb-5">
          <h3>{i18n 'vi:rationale'}</h3>
          <div className="recommendation-content__text-field">
            <GdtEditorWithApply
              ref={rationaleRef}
              stickControlsTo={'.chapter-section-header'}
              editorContent={rationale}
              onApply={saveContent('rationale', rationaleRef)}
              onBlur={saveContent('rationale', rationaleRef)}
            />
          </div>
        </div>
      </div>
      <div className="ml-20">
        <h3>{i18n 'vi:form.strength_of_recommendation'}</h3>
        <Select
          name="strengthOfRecommendation"
          className="w-full"
          onChange={onChangeRecommendationData('strengthOfRecommendation')}
          selected={strengthOfRecommendation}
          options={[
            { text: i18n('vi:form.strength.strong'), value: 'strong'}
            { text: i18n('vi:form.strength.conditional'), value: 'conditional'}
          ]}
        />
        <h3>{i18n 'vi:form.certainty_title'}</h3>
        <SelectWithCheckboxes
          name="certaintyOfEvidence"
          className="w-full"
          onChange={onChangeRecommendationData('certaintyOfEvidence')}
          selectedOptions={certaintyOfEvidence}
          withSelectAll={false}
          options={[
            { text: i18n("vi:form.certainty.#{veryLowText()}"), value: 'very_low'}
            { text: i18n('vi:form.certainty.moderate'), value: 'moderate'}
            { text: i18n('vi:form.certainty.high'), value: 'high'}
            { text: i18n('vi:form.certainty.very_high'), value: 'very_high'}
          ]}
        />
      </div>
    </div>
  </div>

RecommendationContent.propTypes = {
  populationId: PropTypes.string.isRequired
  recommendation: PropTypes.instanceOf(Immutable.Map)
  recommendationId: PropTypes.string.isRequired
  section: PropTypes.instanceOf(Immutable.Map).isRequired
  strengthOfRecommendation: PropTypes.string
}

RecommendationContent.defaultProps = {
  recommendation: Immutable.Map()
  strengthOfRecommendation: null
}

module.exports = RecommendationContent
