ACPVisualGuidelinesActions = require 'actions/acp_visual_guidelines_actions'
ACPVisualGuidelinesStore = require 'stores/acp_visual_guidelines_store'
ConnectStore = require 'components/enhancers/connect_store'
Spinner = require 'components/common/spinner'
{ Visualization } = VIComponents

storeConnector =
  ACPVisualGuidelinesStore: (Store) ->
    isPreparingData: Store.isPreparingData()
    visualizationData: Store.getVisualizationDataForPreview()

VisualizationPreview = createReactClass
  displayName: 'VisualizationPreview'

  propTypes:
    itemId: PropTypes.string.isRequired
    isPreparingData: PropTypes.bool.isRequired
    visualizationData: PropTypes.instanceOf(Immutable.Map).isRequired

  componentDidMount: ->
    ACPVisualGuidelinesActions.prepareDataForVisualizationPreview(@props.itemId)

  render: ->
    {
      itemId,
      isPreparingData,
      visualizationData
    } = @props

    if isPreparingData
      return <Spinner />
    else
      not visualizationData.isEmpty() && <Visualization i18n={i18next} data={visualizationData.toJS()} />

module.exports = ConnectStore VisualizationPreview, [ACPVisualGuidelinesStore], storeConnector
