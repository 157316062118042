var OVERARCHING_TEMPLATES_WITH_OPEN_PV, OVERARCHING_V2_TEMPLATES, PanelVoiceEtdActions, getPanelVoiceAdminSummaryUpdater, getSectionEditToggleHandler, getTagsByKeys, isSectionProposed, _ref,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

PanelVoiceEtdActions = require('actions/panel_voice_etd_actions');

_ref = require('lib/etd_helper'), OVERARCHING_V2_TEMPLATES = _ref.OVERARCHING_V2_TEMPLATES, OVERARCHING_TEMPLATES_WITH_OPEN_PV = _ref.OVERARCHING_TEMPLATES_WITH_OPEN_PV, getTagsByKeys = _ref.getTagsByKeys;

getPanelVoiceAdminSummaryUpdater = function(sectionId, roundTimestamp, etdId) {
  return function(newContent) {
    return PanelVoiceEtdActions.updateAdminVotingSummary({
      etdId: etdId,
      sectionId: sectionId,
      roundTimestamp: roundTimestamp,
      content: newContent
    });
  };
};

getSectionEditToggleHandler = function(sectionId) {
  return function() {
    return PanelVoiceEtdActions.toggleEtdSectionEdit(sectionId);
  };
};

isSectionProposed = function(sectionId, votedEtdData, templateId, votingResults, overarchingQuestionData) {
  var allInterventionsWereProposed, isProposedVoting, overarching, selectedOption, tagIds;
  isProposedVoting = votingResults.get('votingOnProposedOptions');
  tagIds = getTagsByKeys(overarchingQuestionData.get('includedTags'), ['intervention', 'indexTest']).map(function(tag) {
    return tag.get('id');
  });
  overarching = !overarchingQuestionData.isEmpty();
  allInterventionsWereProposed = tagIds.every(function(tagId) {
    return !_.isEmpty(votedEtdData.getIn(['rating', tagId], ''));
  });

  /*
    When you send proposed voting but you haven't check judgment it will be
    treated like not proposed, if the question is overarching then it will be treated as proposed
    only if all interventions have preselected value
   */
  selectedOption = votedEtdData.get('selectedOption', '');
  if (!isProposedVoting) {
    return false;
  } else if (overarching && sectionId === 'problem') {
    return !_.isEmpty(selectedOption);
  } else if (overarching && __indexOf.call(OVERARCHING_TEMPLATES_WITH_OPEN_PV, templateId) >= 0) {
    return allInterventionsWereProposed;
  } else if (overarching && votedEtdData.has('ratingOptions')) {
    return true;
  } else {
    return __indexOf.call(OVERARCHING_V2_TEMPLATES, templateId) >= 0;
  }
};

module.exports = {
  getPanelVoiceAdminSummaryUpdater: getPanelVoiceAdminSummaryUpdater,
  getSectionEditToggleHandler: getSectionEditToggleHandler,
  isSectionProposed: isSectionProposed
};
