var CI_LEVEL, EMPTY_DATA, addDataToOutcome, addMissingInterventions, comparatorMapper, gdtDataToNmaData, gdtInterventionToNmaVizIntervention, gdtOutcomeToNmaVizOutcomeForImport, gdtOutcomeTypeToDefaultEffectMeasure, gdtOutcomeTypeToNmaVizOutcomeType, get, getMappingStepData, importNMAData, interventionMapper, nmaVizInterventionToGdtIntervention, parseEstimate, parseImportResultsToGdtOutcome, partition, pluck, _ref,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

get = require('lodash').get;

_ref = require('lib/immutable_utils'), partition = _ref.partition, pluck = _ref.pluck;

CI_LEVEL = 0.95;

gdtInterventionToNmaVizIntervention = function(interventionImm) {
  return {
    id: interventionImm.get('_id'),
    name: interventionImm.get('name')
  };
};

gdtOutcomeTypeToNmaVizOutcomeType = function(outcomeType) {
  switch (outcomeType) {
    case 'dich':
      return 'Dichotomous';
    case 'cont':
      return 'Continuous';
    case 'timeToEvent':
      return 'TimeToEvent';
    default:
      return 'Other';
  }
};

gdtOutcomeToNmaVizOutcomeForImport = function(outcomeImm) {
  return {
    id: outcomeImm.get('_id'),
    higherIsBetter: Boolean(outcomeImm.get('higherIsBetter')),
    name: outcomeImm.get('name'),
    type: gdtOutcomeTypeToNmaVizOutcomeType(outcomeImm.get('type'))
  };
};

gdtOutcomeTypeToDefaultEffectMeasure = function(outcomeType) {
  switch (outcomeType) {
    case 'dich':
      return 'RR';
    case 'cont':
      return 'MD';
    case 'timeToEvent':
      return 'HR';
    default:
      return 'Other';
  }
};

parseEstimate = function(estimates, estimateType) {
  var estimate;
  estimate = estimates[estimateType];
  if (!estimate) {
    return;
  }
  return {
    ciHigh: estimate.ciHigh,
    ciLevel: CI_LEVEL,
    ciLow: estimate.ciLow,
    effectMeasure: estimate.effectMeasure,
    value: estimate.value
  };
};

parseImportResultsToGdtOutcome = function(_arg, outcomeId) {
  var comparisons, data, interventions, usedInterventionIds;
  comparisons = _arg.comparisons, interventions = _arg.interventions;
  data = _.map(comparisons, function(_arg1) {
    var comparatorId, estimates, interventionId, outcomes;
    comparatorId = _arg1.comparatorId, interventionId = _arg1.interventionId, outcomes = _arg1.outcomes;
    estimates = get(outcomes, "" + outcomeId + ".estimates", []);
    return {
      comparatorId: comparatorId,
      interventionId: interventionId,
      outcomeId: outcomeId,
      nmaEffect: parseEstimate(estimates, 'network'),
      directEffect: parseEstimate(estimates, 'direct'),
      indirectEffect: parseEstimate(estimates, 'indirect')
    };
  });
  usedInterventionIds = _.chain(data).map(function(comparison) {
    return [comparison.interventionId, comparison.comparatorId];
  }).flatten().uniq().value();
  return {
    interventions: interventions,
    data: data,
    usedInterventionIds: usedInterventionIds
  };
};

nmaVizInterventionToGdtIntervention = function(_arg) {
  var id, name;
  id = _arg.id, name = _arg.name;
  return {
    _id: id,
    name: name,
    namesFromFiles: [name]
  };
};

addMissingInterventions = function(question, interventionsToImport) {
  return question.update('interventions', function(currentInterventions) {
    if (currentInterventions == null) {
      currentInterventions = Immutable.List();
    }
    interventionsToImport = _.chain(interventionsToImport).map(nmaVizInterventionToGdtIntervention).reject(function(interventionToCheck) {
      return currentInterventions.some(function(intervention) {
        return intervention.get('_id') === interventionToCheck._id;
      });
    }).map(function(intervention) {
      return Immutable.fromJS(intervention);
    }).value();
    return currentInterventions.concat(interventionsToImport);
  });
};

addDataToOutcome = function(outcome, data, interventionIdsToImport) {
  return outcome.update('data', function(currentData) {
    var dataToImport;
    if (currentData == null) {
      currentData = Immutable.List();
    }
    dataToImport = _.chain(data).filter(function(_arg) {
      var comparatorId, interventionId;
      interventionId = _arg.interventionId, comparatorId = _arg.comparatorId;
      return __indexOf.call(interventionIdsToImport, interventionId) >= 0 && __indexOf.call(interventionIdsToImport, comparatorId) >= 0;
    }).map(function(dataItem) {
      return Immutable.fromJS(dataItem);
    }).value();
    return currentData.concat(dataToImport);
  });
};

importNMAData = function(data, usedInterventions, question, outcome, interventionsMapping, fileName) {
  var interventionsToImport;
  interventionsToImport = _.chain(usedInterventions).map(function(intervention) {
    var mappingValue;
    mappingValue = interventionsMapping.get(intervention.id);
    if ((mappingValue == null) || mappingValue === '_delete') {
      return;
    }
    if (mappingValue === '_add') {
      return intervention;
    }
    return {
      id: mappingValue,
      name: intervention.name
    };
  }).compact().value();
  question = addMissingInterventions(question, interventionsToImport);
  outcome = addDataToOutcome(outcome, data, _.pluck(interventionsToImport, 'id'));
  outcome = outcome.set('fileName', fileName);
  return {
    question: question,
    outcome: outcome
  };
};

getMappingStepData = function(question, parsedData) {
  var data, isNewImport, mapping, matchedInterventions, questionInterventionIds, unmatchedInterventions, usedInterventionIds, usedInterventions, _ref1;
  isNewImport = question.get('interventions', Immutable.List()).isEmpty();
  usedInterventionIds = parsedData.get('usedInterventionIds', Immutable.List());
  questionInterventionIds = pluck(question.get('interventions', Immutable.List()), '_id');
  usedInterventions = parsedData.get('interventions', Immutable.List()).filter(function(intervention) {
    return usedInterventionIds.includes(intervention.get('id'));
  });
  _ref1 = partition(usedInterventions, function(intervention) {
    return isNewImport || questionInterventionIds.includes(intervention.get('id'));
  }), matchedInterventions = _ref1[0], unmatchedInterventions = _ref1[1];
  mapping = isNewImport ? _.chain(usedInterventionIds.toJS()).map(function(interventionId) {
    return [interventionId, '_add'];
  }).object().value() : _.chain(pluck(matchedInterventions, 'id').toJS()).map(function(interventionId) {
    return [interventionId, interventionId];
  }).object().value();
  data = parsedData.get('data', Immutable.List());
  return Immutable.fromJS({
    data: data,
    mapping: mapping,
    matchedInterventions: matchedInterventions,
    unmatchedInterventions: unmatchedInterventions
  });
};

EMPTY_DATA = {
  interventions: [],
  comparisons: [],
  outcomes: []
};

comparatorMapper = function(interventionId, outcomes) {
  return function(comparatorId) {
    var outcomesData;
    if (interventionId === comparatorId) {
      return;
    }
    outcomesData = _.chain(outcomes.toJS()).map(function(outcome) {
      var directEffect, effectToUse, estimates, indirectEffect, nmaEffect, outcomeData, outcomeId, outcomeObj;
      outcomeId = outcome._id;
      outcomeData = _.find(outcome.data, function(dataItem) {
        return dataItem.interventionId === interventionId && dataItem.comparatorId === comparatorId;
      });
      if (!outcomeData) {
        return;
      }
      nmaEffect = outcomeData.nmaEffect, directEffect = outcomeData.directEffect, indirectEffect = outcomeData.indirectEffect, effectToUse = outcomeData.effectToUse;
      estimates = _.chain([['network', nmaEffect], ['direct', directEffect], ['indirect', indirectEffect]]).filter(function(_arg) {
        var value, _estimateType;
        _estimateType = _arg[0], value = _arg[1];
        return value != null;
      }).object().value();
      outcomeObj = _.extend({
        outcomeId: outcomeId,
        estimates: estimates,
        estimateToUse: effectToUse
      }, _.pick(outcomeData, 'interventionData', 'comparisonData', 'numberOfStudies', 'interpretation', 'effectClassification', 'totalNumberOfParticipants'));
      return [outcomeId, outcomeObj];
    }).compact().object().value();
    if (_.isEmpty(outcomesData)) {
      return;
    }
    return {
      interventionId: interventionId,
      comparatorId: comparatorId,
      outcomes: outcomesData
    };
  };
};

interventionMapper = function(interventionIds, outcomes) {
  return function(interventionId) {
    return _.chain(interventionIds).map(comparatorMapper(interventionId, outcomes)).compact().value();
  };
};

gdtDataToNmaData = function(question, outcomes) {
  var comparisons, interventionIds, interventions, outcomesToPass;
  if (question.isEmpty() || outcomes.isEmpty()) {
    return EMPTY_DATA;
  }
  interventions = _.map(question.get('interventions', Immutable.List()).toJS(), function(_arg) {
    var name, _id;
    _id = _arg._id, name = _arg.name;
    return {
      id: _id,
      name: name
    };
  });
  interventionIds = _.pluck(interventions, 'id');
  comparisons = _.chain(interventionIds).map(interventionMapper(interventionIds, outcomes)).flatten().value();
  outcomesToPass = _.map(outcomes.toJS(), function(outcome) {
    var outcomeType;
    outcomeType = gdtOutcomeTypeToNmaVizOutcomeType(outcome.type);
    return _.extend({
      id: outcome._id,
      type: outcomeType
    }, _.pick(outcome, 'name', 'higherIsBetter', 'baselineRisk'));
  });
  return {
    interventions: interventions,
    comparisons: comparisons,
    outcomes: outcomesToPass
  };
};

module.exports = {
  addMissingInterventions: addMissingInterventions,
  CI_LEVEL: CI_LEVEL,
  getMappingStepData: getMappingStepData,
  gdtDataToNmaData: gdtDataToNmaData,
  gdtInterventionToNmaVizIntervention: gdtInterventionToNmaVizIntervention,
  gdtOutcomeToNmaVizOutcomeForImport: gdtOutcomeToNmaVizOutcomeForImport,
  gdtOutcomeTypeToDefaultEffectMeasure: gdtOutcomeTypeToDefaultEffectMeasure,
  importNMAData: importNMAData,
  parseImportResultsToGdtOutcome: parseImportResultsToGdtOutcome
};
