var DbDoc, NMA_OUTCOME_PREFIX, nmaOutcomeDocShape;

DbDoc = require('lib/db_docs/db_doc');

nmaOutcomeDocShape = require('lib/db_docs/doc_shapes/nma_outcome_doc_shape');

NMA_OUTCOME_PREFIX = require('lib/doc_prefixes').nmaOutcome;

module.exports = DbDoc(nmaOutcomeDocShape, {
  dbSetup: {
    idPrefix: NMA_OUTCOME_PREFIX
  },
  methods: {
    updateDescription: function(description) {
      return this.setAt('description')(description);
    }
  }
});
