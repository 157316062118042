{ ExtractionForm } = require 'components/covid_extraction_form/extraction_form'
{ useI18n, useCoffeeCallback } = require 'lib/react_utils'
VisualGuidelinesActions = require 'actions/acp_visual_guidelines_actions'

ACP_VISUAL_GUIDELINES_GENERAL_INFORMATION_FORM = [
  fieldKey: 'guideline_title'
  type: 'textarea'
  showClear: false
,
  fieldKey: 'population'
  type: 'multiText'
  showClear: false
  addValueLabel: $.t 'actions.add'
,
  fieldKey: 'treatment_evaluated'
  type: 'multiText'
  showClear: false
  addValueLabel: $.t 'actions.add'
]

GeneralInformationSection = ({ section }) ->
  i18n = useI18n('vi:form')

  updateGeneralInformations = useCoffeeCallback [section], (field, value) ->
    VisualGuidelinesActions.updateAdditionalData(section.get('_id'), field, value)

  <div>
    <ExtractionForm
      data={section.getIn(['additionalData'], Immutable.Map()).toJS()}
      form={main: ACP_VISUAL_GUIDELINES_GENERAL_INFORMATION_FORM}
      i18n={i18n}
      onChange={updateGeneralInformations}
    />
  </div>

module.exports = GeneralInformationSection
