var ALL_MODULES, CADTH_EXTRACTORS_ORG_ID, HEALTH_ADVISOR_WORKSPACE_ID, MDA_MASTER_PROJECT_ID, MODULES_DISABLED_IN_COPIES, isModuleEnabledInCopy, _ref,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

CADTH_EXTRACTORS_ORG_ID = require('lib/key_messages_helper').CADTH_EXTRACTORS_ORG_ID;

_ref = require('lib/mda_helper'), HEALTH_ADVISOR_WORKSPACE_ID = _ref.HEALTH_ADVISOR_WORKSPACE_ID, MDA_MASTER_PROJECT_ID = _ref.MASTER_PROJECT_ID;

ALL_MODULES = [
  {
    id: 'dashboard',
    accessRights: ['admin'],
    optional: true,
    disabledInCopy: false,
    serverFeatureSwitches: ['mdgFeatures']
  }, {
    id: 'settings',
    accessRights: ['admin'],
    optional: true,
    disabledInCopy: false,
    hideInWorkspace: [CADTH_EXTRACTORS_ORG_ID, HEALTH_ADVISOR_WORKSPACE_ID],
    submodules: [
      {
        id: 'general_information',
        serverFeatureSwitches: ['covidFeatures', 'tbFeatures', 'mdgFeatures'],
        featureSwitch: ['publicationPlatform'],
        featureSwitchOrServerSwitchInterchangeable: true
      }, {
        id: 'etd_templates',
        hideWithFeatureSwitches: ['mdgTables']
      }, {
        id: 'mdg_topics',
        serverFeatureSwitches: ['mdgFeatures']
      }
    ]
  }, {
    id: 'tasks',
    optional: true,
    disabledInCopy: false,
    accessRights: ['admin', 'researcher', 'acp_review_team_member', 'acp_panel_member', 'evolent_panel_reviewer'],
    hideInWorkspace: [CADTH_EXTRACTORS_ORG_ID, HEALTH_ADVISOR_WORKSPACE_ID],
    submodules: [
      {
        id: 'tasks'
      }, {
        id: 'checklist'
      }
    ]
  }, {
    id: 'team',
    accessRights: ['admin'],
    optional: true,
    disabledInCopy: true,
    submodules: [
      {
        id: 'members'
      }, {
        id: 'coi'
      }
    ]
  }, {
    id: 'cadth_guidelines',
    optional: true,
    serverFeatureSwitches: ['cadthFeatures']
  }, {
    id: 'mda_tables',
    optional: true,
    featureSwitch: ['mda'],
    accessRights: ['admin']
  }, {
    id: 'mda_verification',
    optional: true,
    hideInMainProject: [MDA_MASTER_PROJECT_ID],
    featureSwitch: ['mda'],
    accessRights: ['admin']
  }, {
    id: 'mda_topics',
    optional: true,
    featureSwitch: ['mda']
  }, {
    id: 'scope',
    accessRights: ['admin', 'researcher', 'acp_review_team_member'],
    optional: true,
    disabledInCopy: true,
    hideInWorkspace: [CADTH_EXTRACTORS_ORG_ID, HEALTH_ADVISOR_WORKSPACE_ID],
    hideWithFeatureSwitches: ['mdgTables'],
    submodules: [
      {
        id: 'general'
      }, {
        id: 'questions'
      }, {
        id: 'outcomes'
      }
    ]
  }, {
    id: 'references',
    optional: true,
    disabledInCopy: false,
    hideInWorkspace: [CADTH_EXTRACTORS_ORG_ID],
    accessRights: ['admin', 'researcher', 'mda_editor', 'mdg_editor_in_chief', 'acp_review_team_member', 'acp_panel_member', 'cochrane_author'],
    submodules: [
      {
        id: 'duplicates'
      }
    ]
  }, {
    id: 'prognosis',
    accessRights: ['admin', 'researcher', 'acp_review_team_member'],
    optional: true,
    disabledInCopy: false,
    hideInWorkspace: [CADTH_EXTRACTORS_ORG_ID, HEALTH_ADVISOR_WORKSPACE_ID],
    hideWithFeatureSwitches: ['mdgTables']
  }, {
    id: 'evidence_syntheses',
    disabledInCopy: false,
    mandatory: true,
    rejectedAccessRights: ['mdg_panel_reviewer', 'evolent_panel_reviewer'],
    hideInWorkspace: [HEALTH_ADVISOR_WORKSPACE_ID],
    submodules: [
      {
        id: 'outcomes',
        missing: true
      }, {
        id: 'searching',
        missing: true
      }, {
        id: 'screening',
        missing: true
      }, {
        id: 'data_extraction',
        missing: true
      }, {
        id: 'risk_of_bias',
        missing: true
      }, {
        id: 'analyses',
        missing: true
      }, {
        id: 'quality_of_evidence',
        optional: true,
        accessRights: ['admin', 'researcher', 'acp_review_team_member', 'cochrane_author', 'mdg_editor_in_chief'],
        hideWithFeatureSwitches: ['mdgTables']
      }, {
        id: 'quality_indicators',
        optional: true,
        accessRights: ['admin', 'researcher'],
        serverFeatureSwitches: ['ecFeatures', 'covidFeatures']
      }, {
        id: 'recommendations',
        optional: true,
        accessRights: ['admin', 'researcher', 'acp_panel_member', 'mdg_editor_in_chief'],
        hideWithFeatureSwitches: ['mdgTables']
      }, {
        id: 'presentations',
        optional: true,
        accessRights: ['admin', 'researcher', 'acp_panel_member', 'mdg_editor_in_chief'],
        hideWithFeatureSwitches: ['mdgTables']
      }, {
        id: 'mdg_search_strategy',
        optional: true,
        accessRights: ['admin', 'researcher', 'mdg_editor_in_chief'],
        featureSwitch: 'mdgTables'
      }, {
        id: 'mdg_evidence_table',
        optional: true,
        accessRights: ['admin', 'researcher', 'mdg_editor_in_chief'],
        featureSwitch: 'mdgTables'
      }, {
        id: 'mdg_recommendations',
        optional: true,
        accessRights: ['admin', 'researcher', 'mdg_editor_in_chief'],
        featureSwitch: 'mdgTables'
      }
    ]
  }, {
    id: 'multi_comparisons',
    accessRights: ['admin', 'researcher', 'acp_panel_member'],
    disabledInCopy: false,
    hideInWorkspace: [CADTH_EXTRACTORS_ORG_ID, HEALTH_ADVISOR_WORKSPACE_ID],
    hideWithFeatureSwitches: ['mdgTables'],
    optional: true
  }, {
    id: 'algorithms',
    accessRights: ['admin', 'researcher', 'acp_panel_member', 'mdg_editor_in_chief'],
    disabledInCopy: false,
    hideInWorkspace: [CADTH_EXTRACTORS_ORG_ID],
    optional: true,
    featureSwitch: 'algorithms'
  }, {
    id: 'nma',
    accessRights: ['admin', 'researcher', 'acp_panel_member'],
    disabledInCopy: false,
    hideInWorkspace: [CADTH_EXTRACTORS_ORG_ID, HEALTH_ADVISOR_WORKSPACE_ID],
    hideWithFeatureSwitches: ['mdgTables'],
    optional: true,
    featureSwitch: 'nma'
  }, {
    id: 'voting',
    accessRights: ['admin', 'researcher', 'acp_panel_member'],
    optional: true,
    disabledInCopy: true,
    organizationFeatureSwitch: 'voting',
    hideInWorkspace: [CADTH_EXTRACTORS_ORG_ID, HEALTH_ADVISOR_WORKSPACE_ID],
    hideWithFeatureSwitches: ['mdgTables']
  }, {
    id: 'document_sections',
    accessRights: ['admin', 'researcher', 'acp_panel_member', 'mdg_editor_in_chief', 'mdg_panel_reviewer', 'evolent_panel_reviewer'],
    optional: true,
    disabledInCopy: false,
    hideInWorkspace: [CADTH_EXTRACTORS_ORG_ID, HEALTH_ADVISOR_WORKSPACE_ID]
  }, {
    id: 'dissemination',
    accessRights: ['admin', 'acp_panel_member'],
    optional: true,
    disabledInCopy: false,
    hideInWorkspace: [CADTH_EXTRACTORS_ORG_ID],
    submodules: [
      {
        id: 'decision_aids',
        hideWithFeatureSwitches: ['mdgTables'],
        hideInWorkspace: [HEALTH_ADVISOR_WORKSPACE_ID]
      }, {
        id: 'dbep',
        hideWithFeatureSwitches: ['publicationPlatform'],
        hideInWorkspace: [HEALTH_ADVISOR_WORKSPACE_ID]
      }, {
        id: 'publication_platform',
        featureSwitch: 'publicationPlatform',
        hideInWorkspace: [HEALTH_ADVISOR_WORKSPACE_ID]
      }, {
        id: 'mobile',
        hideWithFeatureSwitches: ['mdgTables'],
        hideInWorkspace: [HEALTH_ADVISOR_WORKSPACE_ID]
      }, {
        id: 'mda_topics_workflow',
        showInWorkspace: [HEALTH_ADVISOR_WORKSPACE_ID]
      }, {
        id: 'acp_visual_guidelines',
        featureSwitch: 'visualGuidelines'
      }
    ]
  }
];

MODULES_DISABLED_IN_COPIES = _.chain(ALL_MODULES).filter(function(_arg) {
  var disabledInCopy;
  disabledInCopy = _arg.disabledInCopy;
  return disabledInCopy;
}).pluck('id').value();

isModuleEnabledInCopy = function(moduleId) {
  return __indexOf.call(MODULES_DISABLED_IN_COPIES, moduleId) >= 0;
};

module.exports = {
  ALL_MODULES: ALL_MODULES,
  isModuleEnabledInCopy: isModuleEnabledInCopy
};
