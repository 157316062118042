Checkbox = require 'components/common/checkbox_input'
Translation = require 'components/mixins/translation'

MultiSelect = createReactClass
  displayName: 'MultiSelect'

  propTypes:
    options: PropTypes.arrayOf(PropTypes.shape(
      value: PropTypes.string.isRequired
      label: PropTypes.string.isRequired
      disabled: PropTypes.bool
    )).isRequired
    onChange: PropTypes.func.isRequired
    selectedOptions: PropTypes.arrayOf(PropTypes.string)
    selectAllDisabled: PropTypes.bool.isRequired

  mixins: [Translation()]

  getDefaultProps: ->
    selectedOptions: []
    selectAllDisabled: false

  isSelectAllDisabled: ->
    @props.selectAllDisabled or @props.options.some R.prop('disabled')

  allOptionsChecked: ->
    { options, selectedOptions } = @props
    options.every R.compose R.contains(R.__, selectedOptions), R.prop('value')

  handleOptionChange: (evt) ->
    checked = evt.target.checked
    optionValue = evt.target.value
    newVal = if checked
      R.union @props.selectedOptions, [optionValue]
    else
      R.without [optionValue], @props.selectedOptions

    @props.onChange newVal

  handleAllChange: (evt) ->
    allChecked = evt.target.checked

    @props.onChange if allChecked
      R.pluck 'value', @props.options
    else
      []

  render: ->
    { selectedOptions, options } = @props

    <div className='gdt-multi-select'>
      <div className='gdt-multi-select__header'>
        <label disabled={@isSelectAllDisabled()}>
          <Checkbox
            checked={@allOptionsChecked()}
            onChange={@handleAllChange}
            disabled={@isSelectAllDisabled()}
          />
          <div>{@i18n 'actions.select_all'}</div>
        </label>
      </div>
      <div className='gdt-multi-select__options items-list-container'>
        {options.map ({ value, label, disabled }) =>
          <div className='gdt-multi-select__option' key={value}>
            <label disabled={disabled}>
              <Checkbox
                checked={R.contains value, selectedOptions}
                onChange={@handleOptionChange}
                value={value}
                disabled={disabled}
              />
              <div className='items-list-container__item'>{label}</div>
            </label>
          </div>
        }
      </div>
    </div>

module.exports = MultiSelect
