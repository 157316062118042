var armData, arrayOf, boolean, certaintyAssessment, dataShape, docShape, docTypes, documentId, effectType, initial, number, oneOf, optional, outcomeDocShape, shape, string, _ref;

arrayOf = require('lib/db_docs/field_types/array_of');

documentId = require('lib/db_docs/field_types/document_id');

docShape = require('lib/db_docs/field_types/doc_shape');

docTypes = require('lib/db_docs/doc_types');

initial = require('lib/db_docs/field_types/type_decorators').initial;

oneOf = require('lib/db_docs/field_types/one_of');

shape = require('lib/db_docs/field_types/shape');

_ref = require('lib/db_docs/field_types/built_in_types'), boolean = _ref.boolean, number = _ref.number, string = _ref.string;

optional = shape.typeDecorators.optional;

certaintyAssessment = shape({
  rct: optional(boolean),
  riskOfBias: optional(oneOf(['Not serous', 'Serious', 'Very serious'])),
  inconsistency: optional(oneOf(['Not serous', 'Serious', 'Very serious'])),
  indirectness: optional(oneOf(['Not serous', 'Serious', 'Very serious'])),
  imprecision: optional(oneOf(['Not serous', 'Serious', 'Very serious', 'Extremely serious'])),
  publicationBias: optional(oneOf(['Undetected', 'Strongly suspected'])),
  largeEffect: optional(oneOf(['No', 'Large', 'Very large'])),
  plausibleConfounding: optional(oneOf(['No', 'Would reduce demonstrated effect', 'Would suggest spurious effect'])),
  doseResponseGradient: optional(oneOf(['No', 'Yes'])),
  overall: optional(oneOf(['High', 'Moderate', 'Low', 'Very low']))
});

effectType = shape({
  value: optional(number),
  ciLow: optional(number),
  ciHigh: optional(number),
  ciLevel: optional(number),
  effectMeasure: optional(oneOf(['OR', 'RR', 'Rate Ratio', 'HR', 'RD', 'MD', 'SMD', 'Other'])),
  effectMeasureOther: optional(string),
  certaintyAssessment: optional(certaintyAssessment),
  pValue: optional(number),
  i2: optional(number),
  incoherence: optional(number)
});

armData = shape({
  numberOfParticipants: optional(number),
  numberOfEvents: optional(number),
  mean: optional(number)
});

dataShape = shape({
  interventionId: documentId,
  comparatorId: documentId,
  outcomeId: documentId,
  nmaEffect: optional(effectType),
  directEffect: optional(effectType),
  indirectEffect: optional(effectType),
  interpretation: optional(string),
  effectToUse: optional(oneOf(['nma', 'direct', 'indirect'])),
  effectClassification: optional(oneOf(['Decreased', 'Increased', 'InsufficientData', 'NoEffect'])),
  interventionData: optional(armData),
  comparisonData: optional(armData),
  numberOfStudies: optional(number),
  totalNumberOfParticipants: optional(number)
});

outcomeDocShape = docShape({
  docType: initial(docTypes.NMA_OUTCOME)(string),
  description: optional(string),
  fileName: optional(string),
  name: string,
  shortName: optional(string),
  higherIsBetter: optional(boolean),
  type: initial('dich')(oneOf(['dich', 'cont', 'timeToEvent'])),
  data: arrayOf(dataShape),
  questionId: documentId
});

module.exports = outcomeDocShape;
