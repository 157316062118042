var DashboardActions, INITIAL_STATE, generateStore, mediator;

generateStore = require('stores/utils/generate_store');

DashboardActions = require('actions/dashboard_actions');

mediator = require('mediator');

INITIAL_STATE = Immutable.fromJS({
  meta: {
    isFetching: false,
    confirmationDialogType: null
  }
});

module.exports = generateStore({
  name: 'DashboardStore',
  initialState: INITIAL_STATE,
  boundActions: [DashboardActions],
  methods: {
    onFetch: function() {
      return this.setState(this.state.setIn(['meta', 'fetching'], true));
    },
    onFetchSuccess: function() {
      return this.setState(this.state.setIn(['meta', 'fetching'], false));
    },
    onFetchError: function() {
      return this.setState(this.state.setIn(['meta', 'fetching'], false));
    },
    onOpenConfirmationDialog: function(type) {
      return this.setState(this.state.setIn(['meta', 'confirmationDialogType'], type));
    },
    onCloseConfirmationDialog: function() {
      return this.setState(this.state.setIn(['meta', 'confirmationDialogType'], null));
    },
    getConfirmationDialogType: function() {
      return this.state.getIn(['meta', 'confirmationDialogType'], null);
    }
  }
});
