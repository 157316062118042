mediator = require 'mediator'
IconButton = require 'components/common/icon_button'
Tooltip = require 'components/common/tooltip'
useTranslation = require 'components/hooks/use_translation'
DocumentSectionsV2Actions = require 'actions/document_sections_v2_actions'
{ scrollIntoView } = require 'lib/epiditor_utils'

{ useRef, useCallback, useMemo, useRef, useEffect } = React

SuggestionItem = React.memo ({
  data,
  activeDiscussionItem,
  editingReplyId,
  isDraft,
  sectionId
}) ->
  i18n = useTranslation('docsec:suggestions')
  elRef = useRef(null)
  {
    id
    time
    data: itemData
    author
    summary
    inverseSteps
    invertedSteps
  } = data.toJS()
  { text, replies } = itemData || {}

  active = if _.isArray(activeDiscussionItem)
    _.includes(activeDiscussionItem, id)
  else
    activeDiscussionItem is id

  handleClick = ->
    contentPos = if _.isEmpty(inverseSteps) then invertedSteps[0]?.from  else inverseSteps[0]?.from
    scrollToContent = { sectionId, contentPos }
    projectId = mediator.project.id
    if active
      DocumentSectionsV2Actions.setScrollToSectionContent _.extend scrollToContent, { projectId }
    else
      DocumentSectionsV2Actions.setActiveDiscussionItem { projectId, id, scrollToContent }

  handleChangeDecision = (accepted) ->
    payload = {sectionId, changeId: id, accepted}

    if isDraft
      DocumentSectionsV2Actions.applyDraftChangeDecision payload
    else
      DocumentSectionsV2Actions.saveChangeDecision _.extend payload, projectId: mediator.project.id

  summaryLabel = useMemo(
    ->
      if R.isEmpty(summary)
        return <span>{i18n 'changed_content'}</span>

      { text, format, liftedFrom, wrappedIn, block } = summary;

      if text
        { added, removed } = text

        if removed
          if added
            return (
              <span>
                <span className="bold">{i18n 'replace'}: </span>
                <span className="italic">"{removed}" </span>
                {i18n 'with'}
                <span className="italic"> "{added}"</span>
              </span>
            )
          else
            return (
              <span>
                <span className="bold">{i18n 'remove'}: </span>
                <span className="italic">"{removed}"</span>
              </span>
            )
        else
          return (
            <span>
              <span className="bold">{i18n 'add'}: </span>
              <span className="italic">"{added}"</span>
            </span>
          )
      else if format
        { added, removed } = format

        if added
          return (
            <span>
              <span className="bold">{i18n 'format'}: </span>
              <span className="italic">"{added}"</span>
            </span>
          )
        else if removed
          return (
            <span>
              <span className="bold">{i18n 'format'}: </span>
              {i18n 'remove'}
              <span className="italic"> {removed}</span>
            </span>
          )
      else if liftedFrom
        return (
          <span>
            <span className="bold">{i18n 'format_block'}: </span>
            {i18n 'lift_from'}
            <span className="italic"> {liftedFrom}</span>
          </span>
        )
      else if wrappedIn
        return (
          <span>
            <span className="bold">{i18n 'format_block'}: </span>
            {i18n 'wrap_in'}
            <span className="italic"> {wrappedIn}</span>
          </span>
        )
      else if block
        { changedAttributes, changedType } = block

        if changedAttributes
          return (
            <span>
              <span className="bold">{i18n 'format_block'}: </span>
              <span className="italic">{changedAttributes}</span>
            </span>
          )
        else if changedType
          return (
            <span>
              <span className="bold">{i18n 'change_block_to'}: </span>
              <span className="italic">{changedType}</span>
            </span>
          )

      <span>{i18n 'changed_content'}</span>
    [summary]
  )

  # scroll this item into view when it gets activated
  useEffect(->
    scrollIntoView(elRef.current) if active and elRef.current
    return
  , [active])

  userHavePermissionToReject = useMemo ->
    mediator.user.hasRole ['admin', 'mdg_editor_in_chief']
  , []

  <div
    ref={elRef}
    className={classNames 'discussion-item', 'suggestion', { active }}
    onClick={handleClick}
  >
    <div className="flex flex-row items-center">
      <div className="author-name flex-1 bold truncate">
        {author?.name}
      </div>
      <div className="flex-none flex flex-row items-center">
        <span className="ts mr-10">{moment(parseInt(time)).format('DD MMM YYYY')}</span>
        {userHavePermissionToReject and <React.Fragment>
            <Tooltip>
              <IconButton
                className="p-5"
                title={i18n 'accept_change'}
                iconName="checkmark"
                onClick={() => handleChangeDecision(true)}
              />
            </Tooltip>
            <Tooltip>
              <IconButton
                className="p-5"
                title={i18n 'reject_change'}
                iconName="clear"
                onClick={() => handleChangeDecision(false)}
              />
            </Tooltip>
          </React.Fragment>
        }
      </div>
    </div>
    <div>
      {summaryLabel}
    </div>
  </div>

SuggestionItem.propTypes =
  data: PropTypes.instanceOf(Immutable.Map).isRequired
  sectionId: PropTypes.string.isRequired
  active: PropTypes.bool
  editingReplyId: PropTypes.string

module.exports = SuggestionItem
