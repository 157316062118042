CustomRenderMixin = require 'components/mixins/custom_render_mixin'
utils = require 'base/lib/utils'
mediator = require 'mediator'
Translation = require 'components/mixins/translation'
IconButton = require 'components/common/icon_button'
Button = require 'components/common/button'
{ Fragment } = React
SidebarNodeEditor = require './sidebar_node_editor'
SidebarEdgeEditor = require './sidebar_edge_editor'
SidebarSectionEditor = require './sidebar_section_editor'
SidebarBreakpointEditor = require './sidebar_breakpoint_editor'
SidebarCanvasEditor = require './sidebar_canvas_editor'

minWidthWithModulesClosed = '55px'
minWidthWithModulesOpen = '380px'

Sidebar = createReactClass
  displayName: 'Sidebar'
  mixins: [CustomRenderMixin, Translation('algorithms:sidebar')]

  propTypes:
    currentElement: PropTypes.object
    elements: PropTypes.arrayOf(PropTypes.object).isRequired
    canvasSize: PropTypes.object.isRequired
    onCanvasSizeUpdate: PropTypes.func.isRequired
    onElementAdd: PropTypes.func.isRequired
    onSectionAdd: PropTypes.func.isRequired
    onBreakpointAdd: PropTypes.func.isRequired
    onDiagramExport: PropTypes.func.isRequired
    onNodeDataUpdate: PropTypes.func.isRequired
    onEdgeDataUpdate: PropTypes.func.isRequired
    onElementRemove: PropTypes.func.isRequired

  getInitialState: ->
    collapsed: mediator.user.get('sidebarCollapsed') ? false
    absoluteExpand: not mediator.user.get('sidebarCollapsed')
    absoluteMode: mediator.user.get('sidebarCollapsed')

  componentWillReceiveProps: (nextProps) ->
    minWidth = if mediator.user.get('sidebarCollapsed')
      minWidthWithModulesClosed
    else
      minWidthWithModulesOpen
    ReactDOM.findDOMNode(this).parentNode.style.minWidth = minWidth

  toggleSidebar: ->
    @sidebar.style.position = 'relative'
    collapsed = not @state.collapsed
    @setState
      collapsed: collapsed,
      absoluteExpand: false,
      absoluteMode: collapsed
    mediator.user.set('sidebarCollapsed', collapsed)
    mediator.user.save()
    ReactDOM.findDOMNode(this).parentNode.style.minWidth = if collapsed
      minWidthWithModulesClosed
    else
      minWidthWithModulesOpen
    # force Redraw is used for redrawing sidebar due to safari issues.
    # see https://phabricator.lab.evidenceprime.com/T545
    return unless utils.isSafari()
    _.delay ->
      el = $('#algorithms-sidebar-inner-container')
      if el.length != 0 then el.forceRedraw()
    , 500

  expandSidebar: ->
    return if not @state.collapsed #if siderbar is expanded do nothing
    @sidebar.style.position = 'absolute'
    @setState absoluteExpand: true

  collapseSidebar: (e) ->
    # this will prevent collapsing sidebar when mouse is hovering over tooltip because
    # tooltips are bound to document.body
    return if e.nativeEvent.toElement?.classList.contains('opentip');
    return if not @state.collapsed #if siderbar is expanded do nothing
    @setState absoluteExpand: false

  sidebarEl: (e) -> @sidebar = e

  render: ->
    {
      onElementAdd,
      onSectionAdd,
      onBreakpointAdd,
      onDiagramExport,
      onElementRemove,
      currentElement,
      onEdgeDataUpdate,
      onNodeDataUpdate,
      elements,
      canvasSize,
      onCanvasSizeUpdate
    } = @props

    classes = classNames 'sidebar-inner', utils.getCurrentLanguage(),
      'collapsed': @state.collapsed and not @state.absoluteMode,
      'expanded': not @state.collapsed and not @state.absoluteMode
      'absolute-expand': @state.absoluteExpand and @state.absoluteMode
      'absolute-collapsed': not @state.absoluteExpand and @state.absoluteMode

    btnClassess = classNames(
      'btn-toggle',
      'btn-collapsed': @state.collapsed,
      'btn-expanded': @state.collapsed
    )

    <div id='algorithms-sidebar-inner-container' className={classes}
      onMouseLeave={@collapseSidebar}
      ref={@sidebarEl}>
      <div className='flex-container'>
        <div className='top'>
          <button className={btnClassess} onClick={@toggleSidebar} />
        </div>
        <div id='algorithms-sidebar'>
          {if not @state.collapsed
            <div>
              <div className='algorithms-sidebar__action-buttons'>
                <div className='algorithms-sidebar__action-buttons__export'>
                  <IconButton
                    iconName='export'
                    onClick={onDiagramExport}
                    label={@i18n 'export_table'}
                    labelPosition='right'
                  />
                </div>
                <div className='algorithms-sidebar__action-buttons__add-elements'>
                  <IconButton
                    iconName='plus'
                    onClick={() -> onElementAdd(elements)}
                    label={@i18n 'add_new_element'}
                    labelPosition='right'
                  />
                  <IconButton
                    iconName='plus'
                    onClick={() -> onSectionAdd(elements)}
                    label={@i18n 'add_new_section'}
                    labelPosition='right'
                  />
                  <IconButton
                    iconName='plus'
                    onClick={() -> onBreakpointAdd(elements)}
                    label={@i18n 'add_new_breakpoint'}
                    labelPosition='right'
                  />
                </div>
              </div>

              <div className='algorithms-sidebar__editor-title'>
                {@i18n 'editor_title'}
              </div>

              {currentElement && (
                if currentElement.type == 'unidirectional'
                  <SidebarNodeEditor
                    currentElement={currentElement}
                    onElementRemove={onElementRemove}
                    onNodeDataUpdate={onNodeDataUpdate}
                  />
                else if currentElement.type == 'breakpoint'
                  <SidebarBreakpointEditor
                    currentElement={currentElement}
                    onElementRemove={onElementRemove}
                  />
                else if currentElement.type == 'smoothstep'
                  <SidebarEdgeEditor
                    currentElement={currentElement}
                    onElementRemove={onElementRemove}
                    onEdgeDataUpdate={onEdgeDataUpdate}
                  />
                else if currentElement.type == 'section'
                  <SidebarSectionEditor
                    currentElement={currentElement}
                    onElementRemove={onElementRemove}
                    onSectionDataUpdate={onNodeDataUpdate}
                  />
                else
                  <div />
              )}
              {!currentElement && (
                <div>
                  {@i18n 'no_element_selected'}
                  <SidebarCanvasEditor
                    canvasSize={canvasSize}
                    onCanvasSizeUpdate={onCanvasSizeUpdate}
                  />
                </div>
              )}
            </div>
          }
        </div>
      </div>
    </div>

module.exports = Sidebar
