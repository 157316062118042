AdolopmentConclusionsSection = require 'components/etd/adolopment_conclusions_section'
AdolopmentSectionSeparator = require 'components/etd/adolopment_section_separator'
ConclusionsRow = require 'components/etd/conclusions_row'
ConclusionsSectionAdolopment = require 'components/etd/conclusions_section_adolopment'
ConclusionsSectionTitleRow = require 'components/etd/conclusions_section_title_row'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
EtdActions = require 'actions/etd_actions'
Translation = require 'components/mixins/translation'
UserProjectDataActions = require 'actions/user_project_data_actions'
mediator = require 'mediator'
{ getConsensusBasedField } = require 'lib/etd_helper'

toggleOriginalSection = (etdId, sectionId) -> ->
  UserProjectDataActions.toggleOriginalDataSection etdId, sectionId

toggleAdolopmentSection = (etdId, sectionId) -> ->
  UserProjectDataActions.toggleAdolopmentDataSection etdId, sectionId

ConclusionsSection = createReactClass
  displayName: 'ConclusionsSection'
  mixins: [CustomRenderMixin, Translation('es:recommendations.table')]

  propTypes:
    adolopments: PropTypes.instanceOf(Immutable.Map).isRequired
    etdId: PropTypes.string.isRequired
    etdViewSettings: PropTypes.instanceOf(Immutable.Map).isRequired
    withAdolopment: PropTypes.bool.isRequired

  getDefaultProps: ->
    withTitle: true

  changeSelectedValue: (value) ->
    { sectionId } = @props
    EtdActions.changeSelectedValue { sectionId, value }

  render: ->
    {
      adolopments,
      assessmentSections,
      contentColSpan,
      editable,
      etdId,
      etdViewSettings
      isConsensus,
      readOnly
      renderMode,
      section,
      sectionId,
      withAdolopment
      withTitle
    } = @props

    # apply 'content' class to all text sections. here also checking 'options'
    # property because type of recommendation section has both 'content' and 'options'
    # props, while we shouldn't consider it a 'content' cell section (rather optionsTypeSection)

    contentCellClass = classNames
      'text-content': section.get('content')?
      content: section.get('content')? and not section.get 'options'
      highlighted: @props.highlightToPanelMembers
    descriptionRowSpan = if @props.hasVotingStarted or @props.withPanelVoice then 3 else 1

    sectionsToBeDisplayedAsCurrent = if adolopments.isEmpty()
      section
    else if withAdolopment
      section
    else
      adolopment = adolopments.last()
      adolopment.getIn ['templateData', 'conclusions', 'sections', sectionId]

    originalSectionCollapsed = etdViewSettings.getIn(
      ['collapsedOriginalSections', sectionId], false)

    adolopmentSectionCollapsed = etdViewSettings.getIn(
      ['collapsedAdolopmentSections', sectionId], false)

    editable = editable and (adolopments.isEmpty() or withAdolopment)

    sectionKey = if not adolopments.isEmpty() and withAdolopment
      'adolopment'
    else
      'original'

    readOnly = if adolopments.isEmpty()
      readOnly
    else if withAdolopment
      readOnly
    else
      true

    covidFeaturesOn = mediator.services.switches.isServerSwitchOn('covidFeatures')
    placeholder = if covidFeaturesOn and sectionId is 'implementationConsiderations'
      @i18n '/es:recommendations.placeholders.implementation_considerations'
    else
      ""

    <table className="standard-table recommendation-table-static">
      <tbody>
        <ConclusionsSectionTitleRow
          sectionId={sectionId}
          onChange={@changeSelectedValue}
          options={section.get('options')}
          renderMode={renderMode}
          selectedOption={section.get('selectedOption')}
          title={section.get('description')} colSpan={contentColSpan}
        />
      </tbody>
      {if not adolopments.isEmpty() and withAdolopment
        [
          <tbody key={1}>
            <AdolopmentSectionSeparator
              i18n={@i18n}
              section="original"
              type="conclusions"
              onClick={toggleOriginalSection(etdId, sectionId)}
            />
          </tbody>
          <AdolopmentConclusionsSection
            adolopments={adolopments}
            assessmentSections={assessmentSections}
            attachments={@props.attachments}
            contentCellClass={contentCellClass}
            contentColSpan={contentColSpan}
            etdId={etdId}
            i18n={@i18n}
            isConsensus={isConsensus}
            key={sectionId}
            originalSectionCollapsed={originalSectionCollapsed}
            renderMode={renderMode}
            section={section}
            sectionId={sectionId}
            withSectionTitle={false}
          />
          <tbody key={3}>
            <AdolopmentSectionSeparator
              i18n={@i18n}
              section="adolopment"
              type="conclusions"
              onClick={toggleAdolopmentSection(etdId, sectionId)}
            />
          </tbody>
        ]
      }
      {not adolopmentSectionCollapsed and
        <tbody onClick={@props.onEditClick unless readOnly}>
          <ConclusionsRow
            key={sectionKey}
            assessmentSections={assessmentSections}
            attachments={@props.attachments}
            contentCellClass={contentCellClass}
            contentColSpan={contentColSpan}
            editable={editable}
            isConsensus={isConsensus}
            readOnly={not editable}
            renderMode={renderMode}
            section={sectionsToBeDisplayedAsCurrent}
            sectionId={sectionId}
            placeholder={placeholder}
          />
        </tbody>
      }
    </table>

module.exports = ConclusionsSection
